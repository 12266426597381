import { Component,Input,OnInit } from '@angular/core';

import { ChartService } from 'src/app/components/chart/chart.service';
import { TypeChart } from 'src/app/domain/chart/chart';

@Component({
	templateUrl: './chart-graphique-list.component.html'
})
export class ChartGraphiqueListComponent implements OnInit {
	/** Chart **/
	@Input() chart: any;

	/** Résumé **/
	@Input() resume: { nbDefinitions: number };

	/** Ajout d'un graphique **/
	@Input() addDefinition: (index: number) => void;

	/** Suppression d'un graphique **/
	@Input() deleteDefinition: (close?: (data?: any) => void) => void;

	/** Liste des types de graphique **/
	private listeTypesGraphique: Array<{ type: TypeChart,icon: string,iconType?: string }>;

	/**
	 * Constructeur
	 */
	constructor(private chartService: ChartService) {

	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération des types de graphique
		this.listeTypesGraphique = this.chartService.getListeTypesGraphique();
	}

	/**
	 * Récupération d'un type de graphique par son code
	 */
	getTypeGraphiqueForCode(type: string) {
		//Retour du type de graphique
		return this.listeTypesGraphique.find(t => t.type == type);
	}
}