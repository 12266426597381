import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { EntretienContentComponent } from './entretien-content.component';
import { EntretienListItemComponent } from './entretien-list-item.component';
import { EntretienListComponent } from './entretien-list.component';
import { EntretienComponent } from './entretien.component';
import { EntretienService } from './entretien.service';
import { PlanEntretienAddComponent } from './plan-entretien-add.component';
import { TypeEntretienAddComponent } from './type-entretien-add.component';
import { TypeEntretienService } from './type-entretien.service';
import { EntretienInterventionComponent } from './entretien-intervention.component';
import { VehiculeEntretienInterventionListComponent } from './vehicule-entretien-intervention-list.component';
import { VehiculeEntretienInterventionListItemComponent } from './vehicule-entretien-intervention-list-item.component';
import { EntretienInterventionAddComponent } from './entretien-intervention-add.component';
import { EntretienContreVisiteComponent } from './entretien-contre-visite.component';
import { EntretienContreVisiteAddComponent } from './entretien-contre-visite-add.component';

@NgModule({
	imports: [ShareModule],
	declarations: [EntretienListComponent,EntretienListItemComponent,EntretienComponent,TypeEntretienAddComponent,EntretienContentComponent
		,PlanEntretienAddComponent,EntretienInterventionComponent,VehiculeEntretienInterventionListComponent,VehiculeEntretienInterventionListItemComponent,EntretienInterventionAddComponent,EntretienContreVisiteComponent,EntretienContreVisiteAddComponent
	],
	exports: [EntretienContentComponent],
	providers: [EntretienService,TypeEntretienService]
})
export class EntretienModule {}