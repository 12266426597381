import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ChartGraphiqueListComponent } from './chart-graphique-list.component';
import { ChartGraphiqueEditComponent } from './chart-graphique-edit.component';
import { ChartGraphiqueContentComponent } from './chart-graphique-content.component';

@NgModule({
	imports: [ShareModule],
	declarations: [ChartGraphiqueListComponent,ChartGraphiqueEditComponent,ChartGraphiqueContentComponent],
	exports: [ChartGraphiqueContentComponent]
})
export class ChartDefinitionModule { }