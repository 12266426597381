import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { EntretienService } from './entretien.service';

@Component({
	selector: 'entretien-contre-visite-add',
	templateUrl: './entretien-contre-visite-add.component.html'
})
export class EntretienContreVisiteAddComponent implements AfterContentChecked,OnInit {
	/** Contre-visite **/
	@Input() contreVisite: any;

	/** Entretien **/
	@Input() readonly entretien: any;

	/** Résultat **/
	result: { contreVisite: any };

	/** Liste des types d'avis de contrôle **/
	public listeTypesAvisControle: Array<{ code: string,libelle: string }>;

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<EntretienContreVisiteAddComponent>,public entretienService: EntretienService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Récupération de la liste de types d'avis
		this.listeTypesAvisControle = this.entretienService.getListeTypesAvisControle(true);

		//Vérification de la contre-visite
		if (!this.contreVisite?.typeAvisControle) {
			//Création de la contre-visite si nécessaire
			this.contreVisite = this.contreVisite || {};

			//Initialisation de la contre-visite
			this.contreVisite.typeAvisControle = 'NON_DEFINI';
		}
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Enregistrement de la contre-visite
	 */
	public saveContreVisite() {
		//Définition du résultat
		this.result = {
			contreVisite: this.contreVisite
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la contre-visite
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}
}