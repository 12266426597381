<form #entretienContreVisiteAddForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>entretien.contreVisite.ajoutContreVisite.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="alert alert-info alert-no-shadow">
				<icon name="info"></icon>
				<strong translate [translateParams]="{ dateLimite: entretien.dateLimiteContreVisite | date:'shortDate' }">entretien.contreVisite.alerte.dateLimite</strong>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': typeAvisControleField.invalid || typeAvisControleCheck.invalid }">
				<label translate>entretien.contreVisite.avisControle</label>
				<div>
					<selectpicker name="typeAvisControle" #typeAvisControleField="ngModel" [(ngModel)]="contreVisite.typeAvisControle" [required]="contreVisite.dateRealisation != null">
						<mat-option *ngFor="let typeAvisControle of listeTypesAvisControle" [value]="typeAvisControle.code">{{ typeAvisControle.libelle }}</mat-option>
					</selectpicker>
					<input type="hidden" name="typeAvisControleCheck" #typeAvisControleCheck="ngModel" [(ngModel)]="contreVisite.typeAvisControleCheck" [required]="contreVisite.dateRealisation && contreVisite.typeAvisControle == 'NON_DEFINI'"/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateRealisation.invalid }">
				<label translate>entretien.contreVisite.dateRealisation</label>
				<div class="half">
					<input type="text" class="form-control" name="dateRealisation" #dateRealisation="ngModel" [(ngModel)]="contreVisite.dateRealisation" [minDate]="entretien.dateRealisation" [maxDate]="entretien.dateLimiteContreVisite" datePicker format="date" [storeDate]="true" [required]="contreVisite?.typeAvisControle != 'NON_DEFINI'"/>
				</div>
			</div>
			<div class="form-group" [ngClass]="{ 'has-error': dateRdv.invalid }">
				<label translate>entretien.contreVisite.dateRdv</label>
				<div class="half">
					<input type="text" class="form-control" name="dateRdv" #dateRdv="ngModel" [(ngModel)]="contreVisite.dateRdv" [minDate]="entretien.dateRealisation" [maxDate]="entretien.dateLimiteContreVisite" datePicker format="date" [storeDate]="true"/>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="entretienContreVisiteAddForm.invalid" (click)="saveContreVisite();" *ngIf="null | right:'creation'">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>