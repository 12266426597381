<div class="lv-sm lv-bordered p-t-25 p-b-25">
	<div class="lv-body">
		<div class="lv-item media" *ngFor="let field of chart?.extraction?.listeFields">
			<div class="media-body d-flex align-items-center">
				<div class="pull-left lv-avatar">{{ field.position }}</div>
				<div class="lv-title">{{ field.libelle }}</div>
			</div>
		</div>
		<div class="text-center p-10" *ngIf="!chart.extraction?.listeFields?.length">
			<h5 translate>liste.empty</h5>
		</div>
	</div>
</div>