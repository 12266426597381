import { Component } from '@angular/core';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { LayoutService } from 'src/app/share/layout/layout.service';

@Component({
	templateUrl: './tenant-contrat-list-item.component.html'
})
export class TenantContratListItemComponent extends ListViewItem<any> {
	/** Type de droit */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/**
	 * Constructeur
	 */
	constructor(private layoutService: LayoutService) {
		//Héritage
		super();
	}

	/**
	 * Redirection vers le contrat CRM
	 */
	goToContratCRM() {
		//Navigation vers l'élément
		this.layoutService.goToByState('commerceReferentiel-listeContratsCRM-loadContratCRM',{
			routeParams: {
				idContrat: this.data?.idContrat
			},
			withGoBack: true
		});
	}
}