import { Component,OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter,first } from 'rxjs/operators';
import { AppState } from 'src/app/domain/appstate';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';

import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { IListEntity } from 'src/app/domain/entity/list-entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { HistoryService } from 'src/app/share/components/history/history.service';
import { MailHistoryService } from 'src/app/share/components/mail-history/mail-history.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { mapEntites } from './entretien.entites';
import { EntretienService } from './entretien.service';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './entretien-list-item.component.html'
})
export class EntretienListItemComponent extends ListViewItem<any> implements IListEntity,OnInit {
	/** Enumération des droits **/
	TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	user: User;

	/**
	 * Constructeur
	 */
	constructor(private store: Store<AppState>,private toastrService: ToastrService,private translateService: TranslateService,public entretienService: EntretienService,private confirmService: ConfirmService,private historyService: HistoryService
			,public notificationService: NotificationService,private mailHistoryService: MailHistoryService,private layoutService: LayoutService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe({
			next: user => this.user = user
		});
	}

	/**
	 * Récupération du mapping des entités
	 */
	getMapEntites(): { [entite: string]: string } {
		//Retour du mapping
		return mapEntites;
	}

	/**
	 * Interception d'une modification de l'objet
	 */
	onObjectChange(object: any) {
		//Héritage
		super.onObjectChange(object,this.data?.idEntretien,object?.idEntretien && !this.data?.idEntretien);
	}

	/**
	 * Suppression de l'objet
	 */
	deleteEntretien() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).subscribe({
			next: isConfirmed => {
				//Vérification de la confirmation
				if (isConfirmed) {
					//Suppression de l'entretien
					this.entretienService.deleteEntretien(this.data).subscribe({
						next: (result: Result) => {
							//Vérification du code d'erreur
							if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
								//Message d'information
								this.toastrService.success(this.translateService.instant('actions.suppression.success'));

								//Suppression depuis la liste
								this.onObjectChange(null);
							} else
								//Message d'erreur
								this.toastrService.error(this.translateService.instant('actions.suppression.error'));
						}
					});
				}
			}
		});
	}

	/**
	 * Affichage de l'historique des modifications
	 */
	showHistory() {
		//Affichage de l'historique des modifications
		this.historyService.showHistory('com.notilus.data.vehicule.Entretien',this.data.idEntretien);
	}

	/**
	 * Affichage de l'historique des mails
	 */
	showMailHistory() {
		//Affichage de l'historique des mails
		this.mailHistoryService.showMailHistory('com.notilus.data.vehicule.Entretien',this.data.idEntretien);
	}

	/**
	 * Accès au véhicule
	 */
	goToVehicule() {
		//Navigation vers le véhicule
		this.layoutService.goToByState('listeVehicules-loadVehicule',{
			routeParams: {
				idVehicule: this.data.vehicule.idVehicule
			},
			withGoBack: true
		});
	}

	/**
	 * Accès à la facture
	 */
	goToFacture() {
		//Récupération de l'identifiant de la facture liée à l'entretien
		this.entretienService.findIdFactureForEntretien(this.data).pipe(filter(result => result.data?.idFacture)).subscribe({
			next: result => {
				//Navigation vers la facture
				this.layoutService.goToByState('listeFactures-load',{
					routeParams: {
						idFacture: result.data.idFacture
					},
					withGoBack: true
				});
			}
		})
	}

	/**
	 * Envoi d'une notification
	 */
	sendNotification() {
		//Notification
		this.notificationService.showSelectionMailForEntite('com.notilus.data.vehicule.Entretien',this.data.idEntretien,{
			getOwningEntity: () => this.data,
			typeAttachment: TypeAttachment.ENTRETIEN
		});
	}
}