<form #dashboartChartConfigurationForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>dashboard.chart.configuration.title</h4>
	</div>
	<div class="modal-body p-0 m-0">
		<div class="container-fluid p-0">
			<stepper #stepper="stepper" vertical="true" [listeSteps]="listeSteps" [selectedType]="selectedSection.type"></stepper>
		</div>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="close()">
			<span translate>actions.fermer</span>
		</button>
	</div>
</form>