import { Component,Input,OnInit } from '@angular/core';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { RightService } from 'src/app/share/pipe/right/right.service';

@Component({
	templateUrl: './dashboard-chart-configuration-graphique.component.html'
})
export class DashboardChartConfigurationGraphiqueComponent extends PageContentItem implements OnInit {
	/** Chart **/
	@Input() chart: any;

	/** Index de la définition **/
	@Input() indexDefinition: number;

	/** Graphique **/
	public definition: any;

	/** Options d'affichage **/
	public options: { mode: 'SELECTION' | 'EDITION' | 'CONSULTATION' };

	/**
	 * Constructeur
	 */
	constructor(public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la définition
		this.definition = this.chart.listeDefinitions[this.indexDefinition];

		//Définition du mode d'affichage du formulaire
		this.options = {
			mode: 'CONSULTATION'
		};
	}
}
