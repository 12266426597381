import { Component,OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { ListViewItem } from 'src/app/domain/common/list-view/list-view-item';
import { TypeDroit } from 'src/app/domain/security/right';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { User } from 'src/app/domain/user/user';
import { AppState } from 'src/app/domain/appstate';
import { EntretienService } from 'src/app/components/vehicule/entretien/entretien.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { ConducteurEntretienEditComponent } from './conducteur-entretien-edit.component';

@Component({
	templateUrl: './conducteur-entretien-list-item.component.html'
})
export class ConducteurEntretienListItemComponent extends ListViewItem<any> implements OnInit {
	/** Enumération des droits */
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Utilisateur connecté **/
	public user: User;

	/**
	 * Constructeur
	 */
	constructor(public entretienService: EntretienService,private store: Store<AppState>,private pageContentService: PageContentService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Sélection de l'utilisateur connecté
		this.store.select<User>(state => state.session.user).pipe(first()).subscribe(user => this.user = user);
	}

	/**
	 * Affichage d'un entretien
	 */
	showEntretien() {
		//Chargement complet de l'entretien
		this.entretienService.loadEntretien(this.data.idEntretien).subscribe(result => {
			//Affichage de l'entretien
			this.pageContentService.open(ConducteurEntretienEditComponent,{
				data: {
					entretien: {
						...(result?.data?.entretien || {}),
						readOnly: result?.data?.entretien?.readOnly || !result?.data?.entretien?.previsionnel
					},
					nbInterventions: result?.data?.nbInterventions || 0,
					resume: this.extraOptions.resume,
					liste: this.liste
				}
			},'sub').subscribe((data: { entretien: any }) => {
				//Vérification de la présence de données
				if (data)
					//Rafraichissement de la liste
					this.liste.refresh();
			});
		});
	}
}