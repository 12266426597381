import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer,NgForm } from '@angular/forms';
import { get } from 'lodash-es';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { ChartService } from 'src/app/components/chart/chart.service';
import { SubTypeChart,TypeAgregation,TypeChart } from 'src/app/domain/chart/chart';
import { TypeEntiteValue,entites } from 'src/app/share/components/rule/rule.entites';
import { RuleService } from 'src/app/share/components/rule/rule.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { TypeDroit } from 'src/app/domain/security/right';

@Component({
	selector: 'chart-graphique-content',
	templateUrl: './chart-graphique-content.component.html',
	viewProviders: [{
		provide: ControlContainer,
		useExisting: NgForm
	}]
})
export class ChartGraphiqueContentComponent extends PageContentItem implements OnInit {
	/** Chart **/
	@Input() chart: any;

	/** Index de la définition **/
	@Input() indexDefinition: number;

	/** Méthode de marquage du formulaire **/
	@Input() formMarkAsDirty: () => void;

	/** Graphique **/
	@Input() definition: any;

	/** Options d'affichage **/
	@Input() options: { mode: 'SELECTION' | 'EDITION' | 'CONSULTATION' };

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Clé 1 sélectionnée **/
	public selectedCle1: any = null;

	/** Clé 2 sélectionnée **/
	public selectedCle2: any = null;

	/** Liste des types de graphique **/
	public listeTypesGraphique: Array<{ type: TypeChart,icon: string,iconType?: string,listeOptions?: Array<string> }>;

	/** Liste des types d'agrégation **/
	public listeTypesAgregation: Array<{ code: string,libelle: string }>;

	/** Liste des types de tri **/
	public listeTypesSort: Array<{ code: string,libelle: string }>;

	/** Liste des types d'ordre **/
	public listeTypesOrder: Array<{ code: string,libelle: string }>;

	/** Type de graphique sélectionné **/
	public selectedTypeGraphique: { type: TypeChart,icon: string,iconType?: string,listeOptions?: Array<string>,listeSubTypes?: Array<string> };

	/** Liste des données métier disponibles **/
	public listeFields: Array<{ name: string,libelle: string }>;

	/** Liste des icônes **/
	public listeIcones: Array<{ reference: string,code: string }>;

	/**
	 * Constructeur
	 */
	constructor(private chartService: ChartService,private ruleService: RuleService,private confirmService: ConfirmService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération de la liste des types de graphique
		this.listeTypesGraphique = this.chartService.getListeTypesGraphique();

		//Récupération de la liste des types d'agregation
		this.listeTypesAgregation = this.ruleService.getListeTypesAgregation();

		//Récupération de la liste des types de tri
		this.listeTypesSort = this.chartService.getListeTypesSort();

		//Récupération de la liste des types d'ordre
		this.listeTypesOrder = this.chartService.getListeTypesOrder();

		//Récupération de la liste des données métiers disponibles
		this.listeFields = this.chart.extraction.listeFields?.filter(f => !f.collection) || [];

		//Récupération de la liste des icônes
		this.listeIcones = this.chartService.getListeIcones();

		//Récupération du type de graphique sélectionné
		this.selectedTypeGraphique = this.definition.type ? this.listeTypesGraphique.find(t => t.type == this.definition.type) : null;

		//Initialisation de la première clé
		this.onCleChange(this.definition.listeCles?.[0]?.cle,0);

		//Initialisation de la seconde clé
		this.onCleChange(this.definition.listeCles?.[1]?.cle,1);
	}

	/**
	 * Sélection du type de graphique
	 */
	selectTypeGraphique(typeGraphique) {
		let isPieToDonut: boolean;

		//Vérification du changement de type de graphique
		if (typeGraphique != this.selectedTypeGraphique) {
			//Mise à jour du type de graphique sélectionné
			this.selectedTypeGraphique = typeGraphique;

			//Récupération du type de changement
			isPieToDonut = [TypeChart.PIE,TypeChart.DONUT].includes(typeGraphique.type) && [TypeChart.PIE,TypeChart.DONUT].includes(this.selectedTypeGraphique.type);

			//Réinitialisation des options
			Object.assign(this.definition,{
				type: this.definition.type != typeGraphique.type ? typeGraphique.type : TypeChart.NON_DEFINI,
				subType: this.definition.type != typeGraphique.type ? this.definition.subType : SubTypeChart.NON_DEFINI,
			});

			//Vérification du type de changement
			if (!isPieToDonut) {
				//Remise à zéro des tris
				this.definition.typeSort = 'LABEL';
				this.definition.typeOrder = 'ASCENDING';

				//Vérification du type de graphique
				if (this.definition.type == TypeChart.KPI) {
					//Retrait des clés de groupement
					this.definition.listeCles = null;

					//Vérification de la clé sélectionnée
					if (this.selectedCle1)
						//Retrait des groupements
						this.selectedCle1.listeGroupements = null;

					//Vérification de la clé sélectionnée
					if (this.selectedCle2)
						//Retrait des groupements
						this.selectedCle2.listeGroupements = null;
				} else {
					//Remise à zéro des clés de groupement
					this.definition.listeCles = [{}];
				}

				//Modification du sous-type
				this.selectSubType(SubTypeChart.NON_DEFINI);
			}
		}

		//Passage en mode édition
		this.options.mode = 'EDITION';
	}

	/**
	 * Sélection du sous-type de graphique
	 */
	selectSubType(subType: SubTypeChart) {
		//Mise à jour du sous-type
		this.definition.subType = subType;

		//Vérification du type de graphique
		if (subType == SubTypeChart.NON_DEFINI) {
			//Retrait de la seconde clé de groupement
			this.definition.listeCles?.length > 1 && this.definition.listeCles.pop();

			//Remise à zéro de la clé
			this.selectedCle2 = null;
		} else if (!this.definition.listeCles[1]) {
			//Initialisation d'une seconde clé de groupement
			this.definition.listeCles.push({});
		}
	}

	/**
	 * Vérification de la sélection d'une option
	 */
	isOptionSelected(option: string): boolean {
		let isSelected: boolean = false;

		//Vérification de la sélection
		switch (option) {
		case 'DATA':
			//Accès aux données
			isSelected = this.definition.data;
			break;
		case 'SEUIL':
			//Seuil
			isSelected = this.definition.seuil;
			break;
		case 'CONDITION':
			//Condition
			isSelected = this.definition.condition;
			break;
		case 'INTERACTION':
			//Interaction
			isSelected = this.definition.interaction;
			break;
		case 'NOTIFICATION':
			//Notification
			isSelected = this.definition.notification;
			break;
		}

		return isSelected;
	}

	/**
	 * Sélection/Désélection d'une option
	 */
	toggleOption(option: string) {
		//Vérification de la sélection
		switch (option) {
		case 'DATA':
			//Accès aux données
			this.definition.data = !this.definition.data;
			break;
		case 'SEUIL':
			//Seuil
			this.definition.seuil = !this.definition.seuil;
			break;
		case 'CONDITION':
			//Condition
			this.definition.condition = !this.definition.condition;
			break;
		case 'INTERACTION':
			//Icône
			this.definition.interaction = !this.definition.interaction;
			break;
		case 'NOTIFICATION':
			//Notification
			this.definition.notification = !this.definition.notification;
			break;
		}
	}

	/**
	 * Récupération de la liste filtrée des champs pour l'agrégation
	 */
	getListeFieldsForAgregation() {
		//Vérification du type d'agrégation
		if (this.definition.typeAgregation != TypeAgregation.COUNT && this.definition.typeAgregation != TypeAgregation.DISTINCT_COUNT)
			//Retour de la liste filtrée
			return this.chart.extraction.listeFields?.filter(item => ['java.lang.Double','java.lang.Long','java.lang.Integer'].includes(item.type));
		else
			//Retour de la liste complète
			return this.chart.extraction.listeFields;
	}

	/**
	 * Interception d'un changement de clé
	 */
	onCleChange(cle: string,index: number) {
		let numeroCle: number;

		//Vérification de la clé
		if (cle) {
			//Définition du numéro de clé
			numeroCle = index + 1;

			//Récupération de la clé
			this['selectedCle'+numeroCle] = this.chart.extraction.listeFields?.find(field => field.name == cle);

			//Vérification de la clé sélectionnée
			if (this['selectedCle'+numeroCle]) {
				//Définition du type de clé
				this.definition.listeCles[index].typeCle = this['selectedCle'+numeroCle].type;

				//Récupération de la liste des regroupements disponibles pour le type de clé
				this['selectedCle'+numeroCle].listeGroupements = (get(entites,this['selectedCle'+numeroCle].type) as TypeEntiteValue).listeGroupements;

				//Vérification de la présence du groupement
				if (this.definition.listeCles[index].typeGroupement && !this['selectedCle'+numeroCle].listeGroupements?.find(code => this.definition.listeCles[index].typeGroupement == code))
					//Réinitialisation du type de regroupement
					this.definition.listeCles[index].typeGroupement = null;
			}
		}
	}

	/**
	 * Interception du changement de type d'agrégation
	 */
	onTypeAgregationChange() {
		//Vérification que la valeur n'est plus sélectionnable
		if (!this.getListeFieldsForAgregation().find(f => f.name == this.definition.value))
			//Remise à zéro de la valeur
			this.definition.value = null;
	}

	/**
	 * Sélection d'une icône
	 */
	selectIcone(reference: string) {
		//Retrait ou mise à jour de l'icône sélectionnée
		this.definition.icone = reference == this.definition.icone ? null : reference;
	}
}