import { CurrencyPipe } from '@angular/common';
import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep,isEqual } from 'lodash-es';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { filter,first,map } from 'rxjs/operators';

import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { TypeDroit,TypeRestriction } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ImmatriculationService } from './immatriculation/immatriculation.service';
import { ConfigurationService } from './modele/configuration/configuration.service';
import { ModeleService } from './modele/modele.service';
import { VehiculeEnginComponent } from './vehicule-engin.component';
import { VehiculeInformationTarifaireComponent } from './vehicule-information-tarifaire.component';
import { VehiculeService } from './vehicule.service';
import { LoggedUserService } from 'src/app/share/login/logged-user.service';

@Component({
	selector: 'vehicule-edit',
	templateUrl: './vehicule-edit.component.html'
})
export class VehiculeEditComponent extends PageContentItem implements OnInit {
	/** Elément courant **/
	@Input() vehicule: any;

	/** Suppression de l'objet **/
	@Input() deleteVehicule: () => void;

	/** Utilisateur courant **/
	@Input() user: User;

	/** Configuration du pays **/
	@Input() configurationPays: any;

	/** Utilisateur d'origine **/
	@Input() userOrigine: User;

	/** Liste des immatriculations du modèle **/
	@Input() listeImmatriculationModeles: Array<any>;

	/** Liste des types de Crit'Air **/
	public listeTypesCritAir: Array<{ code: string,libelle: string }>;

	/** Liste des types d'unité d'usage **/
	public listeTypesUniteUsage: Array<{ code: string,libelle: string }>;

	/** Liste des canaux de synchronisation SGI **/
	public listeCanauxSynchroSGI: Array<{ code: string,libelle: string }>;

	/** Enumération des droits **/
	public TypeDroit: typeof TypeDroit = TypeDroit;

	/** Enumération des types de véhicule **/
	public TypeVehicule: typeof TypeVehicule = TypeVehicule;

	/** Enumération des types d'attachment **/
	public TypeAttachment: typeof TypeAttachment = TypeAttachment;

	/** Vérification des périodes */
	public periode: { isDateEntreeValid: boolean,isDateSortieValid: boolean } = { isDateEntreeValid: true,isDateSortieValid: true };

	/**
	 * Constructeur
	 */
	constructor(private toastrService: ToastrService,public rightService: RightService,private translateService: TranslateService,public vehiculeService: VehiculeService,private immatriculationService: ImmatriculationService,private currencyPipe: CurrencyPipe,private bsModalService: BsModalService,private modeleService: ModeleService,private configurationService: ConfigurationService,private layoutService: LayoutService,private loggedUserService: LoggedUserService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Vérification des dates d'entrée/sortie du véhicule
		this.isPeriodeValidForVehicule();

		//Définition de la liste des types de Crit'Air
		this.listeTypesCritAir = this.vehiculeService.getListeTypesCritAir();

		//Définition de la liste des types d'unité d'usage
		this.listeTypesUniteUsage = this.vehiculeService.getListeTypesUniteUsage();

		//Définition de la liste des canaux de synchronisation SGI
		this.listeCanauxSynchroSGI = this.vehiculeService.getListeCanauxSynchroSGI();

		//Vérification de l'absence d'un certificat d'immatriculation
		if (this.vehicule?.reference && !this.vehicule.immatriculation && this.vehicule.typeVehicule != TypeVehicule.ENGIN) {
			//Affichage de la sélection d'une immatriculation
			this.vehiculeService.showImmatriculationSelection((data: { immatriculation: any,params: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Redirection vers le véhicule
					this.layoutService.goToByState('listeVehicules-loadVehicule',{
						routeParams: {
							idVehicule: this.vehicule.idVehicule
						},
						routeData: {
							immatriculation: data.immatriculation,
							modele: data.params.modele
						},
						reloadOnSameUrl: true
					});
				}
			},{
				isDateEntreeVisible: false,
				isWithEngin: false,
				vehicule: this.vehicule,
				useExistingImmatriculation: true,
				isSynchro: true
			}).subscribe();
		}
	}

	/**
	 * Enregistrement de l'objet
	 */
	public saveVehicule(vehicule: any = cloneDeep(this.vehicule),isWebSocket: boolean = true) {
		//Vérification du type de véhicule
		if (![TypeVehicule.VEHICULE_FONCTION_SERVICE,TypeVehicule.ENGIN].includes(vehicule.typeVehicule)) {
			//Suppression des données liées aux véhicules de fonction/société ou aux engins
			delete vehicule.vin;
			delete vehicule.dateEntree;
			delete vehicule.dateSortie;
		} else
			//Suppression des données liées aux véhicules personnels
			delete vehicule.user;

		//Enregistrement de l'objet
		this.vehiculeService.saveVehicule(vehicule,isWebSocket).subscribe({
			next: (result: Result) => {
				let listeDoublons = new Array<string>();

				//Vérification du code d'erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Mise à jour de l'objet
					Object.assign(this.vehicule,result.data?.vehicule);

					//Vérification de l'indicateur de rafraichissement du véhicule (WebSocket)
					if (!result?.data?.refresh) {
						//Message d'information
						this.toastrService.success(this.translateService.instant('actions.enregistrement.success'));

						//Fermeture du formulaire
						this.close?.(this.vehicule);
					}
				} else {
					//Vérification du code d'erreur
					if (result?.codeErreur == TypeCodeErreur.DOUBLON) {
						//Vérification du type de véhicule
						if (vehicule.typeVehicule == TypeVehicule.ENGIN) {
							//Numéro / Marque
							listeDoublons.push(this.translateService.instant('actions.doublon.numero'));
							listeDoublons.push(this.translateService.instant('actions.doublon.marque'));
						} else
							//Immatriculation
							listeDoublons.push(this.translateService.instant('actions.doublon.immatriculation'));

						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.doublon.enregistrement',{
							field: listeDoublons.join(', ')
						}));
					} else {
						//Message d'erreur
						this.toastrService.error(this.translateService.instant('actions.enregistrement.error'));
					}
				}
			},
		});
	}

	/**
	 * Vérification de la période avec la liste d'affectations
	 */
	public isPeriodeValidForVehicule(): void {
		//Vérification de la présence d'un identifiant de véhicule
		if (this.vehicule?.idVehicule) {
			//Vérification de la période avec la liste d'affectations
			this.vehiculeService.isPeriodeValidForVehicule(this.vehicule).subscribe({
				next: periode => this.periode = periode
			});
		}
	}

	/**
	 * Récupération du libellé des informations tarifaires
	 */
	public getLibelleInformationTarifaire(): string {
		let libelle: string;

		//Vérification du genre
		if (this.vehicule?.genre?.modeAffichagePrix == 'HT') {
			//Vérification du montant HT
			if (this.vehicule?.informationTarifaire?.prixHTRemise > 0)
				//Montant HT remisé
				libelle = this.translateService.instant('vehicule.informationTarifaire.montantNetHT',{ montant: this.currencyPipe.transform(this.vehicule?.informationTarifaire?.prixHTRemise,this.vehicule?.informationTarifaire?.devise) });
			else
				//Aucun montant HT
				libelle = this.translateService.instant('vehicule.informationTarifaire.aucunMontantHT');
		} else if (this.vehicule?.genre?.modeAffichagePrix == 'TTC') {
			//Vérification du montant TTC
			if (this.vehicule?.informationTarifaire?.prixTTCRemise > 0)
				//Montant TTC remisé
				libelle = this.translateService.instant('vehicule.informationTarifaire.montantNetTTC',{ montant: this.currencyPipe.transform(this.vehicule?.informationTarifaire?.prixTTCRemise,this.vehicule?.informationTarifaire?.devise) });
			else
				//Aucun montant TTC
				libelle = this.translateService.instant('vehicule.informationTarifaire.aucunMontantTTC');
		} else
			//Libellé par défaut
			libelle = this.translateService.instant('vehicule.informationTarifaire.aucunMontantHT');

		return libelle;
	}

	/**
	 * Ouverture de la popup d'immatriculation
	 */
	public showImmatriculation(isSynchro: boolean = true): void {
		//Ouverture de la popup d'immatriculation
		this.immatriculationService.showImmatriculation({
			immatriculation: this.vehicule.immatriculation,
			pays: this.vehicule.pays,
			typeVehicule: this.vehicule.typeVehicule,
			vehicule: this.vehicule,
			options: {
				isSynchro
			}
		}).subscribe({
			next: ({ vehicule,immatriculation }) => {
				//Vérification d'un changement de modèle constructeur
				if (vehicule?.modeleConstructeur?.idModele != this.vehicule?.modeleConstructeur?.idModele) {
					//Mise à jour de l'objet
					Object.assign(this.vehicule,vehicule);

					//Enregistrement du véhicule (sans WebSocket)
					this.saveVehicule(this.vehicule,false);
				} else if (immatriculation) {
					//Rechargement de l'écran
					this.layoutService.goToByState('listeVehicules-loadVehicule',{
						routeParams: {
							idVehicule: vehicule.idVehicule
						},
						reloadOnSameUrl: true
					});
				}
			}
		});
	}

	/**
	 * Récupération des modèles catalogue depuis l'immatriculation
	 */
	public filtreModeleConstructeurForImmatriculation(immatriculation: any = this.vehicule.immatriculation): Array<any> {
		//Récupération des modèles actifs présents dans le catalogue
		return immatriculation?.typeSource == 'IMPORT' && this.listeImmatriculationModeles?.length && this.listeImmatriculationModeles?.filter((i: any) => i?.modeleCatalogue?.actif) || [];
	}

	/**
	 * Ouverture de la popup de la informationTarifaire
	 */
	public showInformationTarifaire(informationTarifaire = cloneDeep(this.vehicule?.informationTarifaire) || ({ devise: this.user.devise })) {
		let informationTarifaireConstructeur: any;

		//Fonction d'ouverture de la pop-up
		const doShowInformationTarifaire = (informationTarifaireConstructeur?: any) => {
			let bsModalRef: BsModalRef<VehiculeInformationTarifaireComponent>;

			//Affichage de la popup
			bsModalRef = this.bsModalService.show(VehiculeInformationTarifaireComponent,{
				initialState: {
					informationTarifaire: cloneDeep(informationTarifaire),
					informationTarifaireConstructeur
				}
			});

			//Retour du résultat
			bsModalRef.onHidden.pipe(
				first(),
				map(() => bsModalRef.content?.result?.informationTarifaire),
				filter(rInformationTarifaire => !!rInformationTarifaire && !isEqual(rInformationTarifaire,informationTarifaire))
			).subscribe({
				next: (rInformationTarifaire: any) => {
					//Définition du résultat
					this.vehicule.informationTarifaire = rInformationTarifaire;

					//Définition de la modification manuelle
					this.vehicule.informationTarifaire.userEdited = true;
				}
			});
		}

		//Vérification du modèle constructeur
		if (this.vehicule?.modeleConstructeur) {
			//Vérification des informations du modèle constructeur
			if (this.vehicule?.modeleConstructeur?.information?.commercialisationPrix) {
				//Initialisation des prix du modèle constructeur
				informationTarifaireConstructeur = this.vehicule.modeleConstructeur.information.commercialisationPrix;

				//Ouverture du formulaire des informations tarifaires
				doShowInformationTarifaire(informationTarifaireConstructeur);
			} else {
				//Chargement du modèle
				this.modeleService.loadModele(this.vehicule?.modeleConstructeur?.idModele).subscribe({
					next: (result: Result) => {
						//Vérification du code d'erreur
						if (result.codeErreur == TypeCodeErreur.NO_ERROR)
							//Initialisation des prix du modèle constructeur
							informationTarifaireConstructeur = result.data.modele.information.commercialisationPrix;

						//Ouverture du formulaire des informations tarifaires
						doShowInformationTarifaire(informationTarifaireConstructeur);
					}
				});
			}
		} else
			//Ouverture du formulaire des informations tarifaires
			doShowInformationTarifaire();
	}

	/**
	 * Ouverture de la popup du modèle constructeur
	 */
	public showModeleConstructeur() {
		//Ouverture de la popup du modèle constructeur
		this.modeleService.showModeleInformations(cloneDeep(this.vehicule?.modeleConstructeur),{
			canChange: this.filtreModeleConstructeurForImmatriculation()?.length > 0,
			canSelect: false,
			canConfigure: this.vehicule.idVehicule != null
		}).subscribe({
			next: ({ action }: { action: 'CHANGE_MODELE' | 'CONFIGURE_MODELE',modele: any }) => {
				//Vérification de l'action effectuée
				if (action == 'CHANGE_MODELE') {
					//Changement du modèle
					this.doChangeModele();
				} else if (action == 'CONFIGURE_MODELE') {
					//Ouverture de la pop-up de configuration
					this.configurationService.showConfiguration({
						type: 'VEHICULE',
						idEntite: this.vehicule.idVehicule,
						idModele: this.vehicule.modeleConstructeur.idModele,
						isEdition: true
					}).subscribe();
				}
			}
		});
	}

	/**
	 * Changement du modèle
	 */
	public doChangeModele(immatriculation: any = this.vehicule.immatriculation) {
		let listeModelesConstructeur: Array<any>;

		//Récupération des modèles constructeurs
		listeModelesConstructeur = this.filtreModeleConstructeurForImmatriculation(immatriculation);

		//Vérification de la présence de modèles constructeur
		if (listeModelesConstructeur?.length > 1) {
			//Affichage de la configuration du modèle
			this.immatriculationService.showListeModelesForImmatriculation(immatriculation).subscribe({
				next: (selectedModele: any) => {
					//Mise à jour du modèle
					this.vehicule.modeleConstructeur = selectedModele;
				}
			})
		} else if (listeModelesConstructeur.length == 1)
			//Mise à jour du modèle
			this.vehicule.modeleConstructeur = listeModelesConstructeur[0].modeleCatalogue;
	}

	/**
	 * Ouverture de la popup de l'engin
	 */
	public showEngin() {
		let bsModalRef: BsModalRef<VehiculeEnginComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(VehiculeEnginComponent,{
			initialState: {
				vehicule: cloneDeep(this.vehicule)
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.vehicule),
			filter(vehicule => !!vehicule)
		).subscribe({
			next: (vehicule: any) => {
				//Définition du résultat
				Object.assign(this.vehicule,vehicule);
			}
		});
	}

	/**
	 * Vérification de la nécessité de renseigner un pool de véhicules
	 */
	public isPoolRequired(): boolean {
		//Vérification de l'existence d'un profil avec restriction par pool de véhicules pour l'utilisateur
		return this.loggedUserService.getUser()?.listeUserProfil?.some(lien => lien?.profil?.typeProfil == 'WEB' && lien?.profil?.typeRestriction == TypeRestriction.VEHICULE_POOL);
	}
}