import { Component,Input,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { filter,tap } from 'rxjs/operators';

import { PageContentItem } from 'src/app/domain/common/page-content/page-content-item';
import { SubTypeChart,TypeChart } from 'src/app/domain/chart/chart';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';

@Component({
	templateUrl: './chart-graphique-edit.component.html'
})
export class ChartGraphiqueEditComponent extends PageContentItem implements OnInit {
	/** Chart **/
	@Input() chart: any;

	/** Enregistrement du graphique **/
	@Input() saveChart: (chart: any,close?: Function,hasEntityChanged?: boolean) => void;

	/** Suppression de la définition **/
	@Input() deleteDefinition: (close?: (data?: any) => void) => void;

	/** Index de la définition **/
	@Input() indexDefinition: number;

	/** Graphique **/
	public definition: any;

	/** Options d'affichage **/
	public options: { mode: 'SELECTION' | 'EDITION'};

	/**
	 * Constructeur
	 */
	constructor(private confirmService: ConfirmService,private translateService: TranslateService,public rightService: RightService) {
		//Héritage
		super();
	}

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Récupération ou création de la définition courante
		this.definition = this.chart.listeDefinitions[this.indexDefinition] || {
			type: TypeChart.NON_DEFINI,
			subType: SubTypeChart.NON_DEFINI,
			typeSort: 'LABEL',
			typeOrder: 'ASCENDING',
			listeCles: [{}]
		};

		//Vérification de la création
		if (!this.definition.idDefinition) {
			//Ajout du nouvel élément à la liste
			this.chart.listeDefinitions = [
				...(this.chart.listeDefinitions || []),
				this.definition
			];
		}

		//Définition du mode d'affichage du formulaire
		this.options = {
			mode: this.definition.type == 'NON_DEFINI' ? 'SELECTION' : 'EDITION'
		};
	}

	/**
	 * Enregistrement de la définition
	 */
	saveDefinition() {
		//Vérification du type du premier graphique
		(this.chart.listeDefinitions[0].type == TypeChart.KPI && this.chart.listeDefinitions.length > 1 ? this.confirmService.showConfirm(this.translateService.instant('chart.confirmation.suppressionGraphiqueSecondaire')).pipe(
			filter(isConfirmed => !!isConfirmed),
			tap(() => {
				//Suppression du second graphique
				this.chart.listeDefinitions.length = 1;
			})
		) : of(true)).subscribe(() => {
			//Enregistrement du chart
			this.saveChart(this.chart,this.close.bind(this));
		});
	}
}