<div class="container-fluid">
	<form #formatInterfaceForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
		<div class="card full">
			<div class="card-body card-padding">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': libelle.invalid }">
								<label translate>fournisseur.formatInterface.libelle</label>
								<div>
									<input type="text" name="libelle" #libelle="ngModel" class="form-control" [(ngModel)]="formatInterface.libelle" maxlength="300" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': reference.invalid }">
								<label translate>fournisseur.formatInterface.reference</label>
								<div>
									<input type="text" name="reference" #reference="ngModel" class="form-control" [(ngModel)]="formatInterface.reference" maxlength="100" required/>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': fournisseurImport.invalid }">
								<label translate>fournisseur.formatInterface.fournisseurImport</label>
								<div>
									<autocomplete name="fournisseurImport" type="fournisseur" #fournisseurImport="ngModel" [filter]="{ typeReferentiel: TypeReferentiel.STANDARD,typeSaisie: 'IMPORT' }" [isCreationDisabled]="true" [(ngModel)]="formatInterface.fournisseurImport"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
								<label translate>fournisseur.formatInterface.fournisseur</label>
								<div>
									<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [filter]="{ typeReferentiel: TypeReferentiel.STANDARD,typeSaisie: 'SAISIE' }" [(ngModel)]="formatInterface.fournisseur" (ngModelChange)="!formatInterface?.fournisseur && formatInterface.actif = false"></autocomplete>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': typeFluxField.invalid }">
								<label translate>fournisseur.formatInterface.typeFlux.item</label>
								<div>
									<selectpicker name="typeFlux" #typeFluxField="ngModel" [(ngModel)]="formatInterface.typeFlux">
										<mat-option *ngFor="let typeFlux of listeTypesFlux" [value]="typeFlux.code">{{ typeFlux.libelle }}</mat-option>
									</selectpicker>
								</div>
							</div>
							<div class="form-group" [ngClass]="{ 'has-error': nbFiles.invalid }">
								<label translate>fournisseur.formatInterface.nbFiles</label>
								<div class="half">
									<input type="text" name="nbFiles" #nbFiles="ngModel" class="form-control" [(ngModel)]="formatInterface.nbFiles" nNumber min="0"/>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group" [ngClass]="{ 'has-error': actif.invalid }">
								<label>
									<span translate>fournisseur.formatInterface.actif.item</span>
									<icon name="info_outline" [tooltip]="'fournisseur.formatInterface.actif.description' | translate" class="m-l-5"></icon>
								</label>
								<div>
									<mat-checkbox name="actif" #actif="ngModel" [(ngModel)]="formatInterface.actif" [disabled]="!formatInterface.actif && !formatInterface.fournisseur"><span translate>fournisseur.formatInterface.actif.info</span></mat-checkbox>
								</div>
							</div>
							<div class="form-group">
								<label translate>fournisseur.formatInterface.nbVersions</label>
								<div>
									<span>{{ (formatInterface.listeVersions?.length || 0) | number }}</span>
									<icon name="info_outline" class="m-l-5" [tooltip]="formatInterface.listeVersions?.join(', ')"></icon>
								</div>
							</div>
							<div class="form-group">
								<label translate>fournisseur.formatInterface.modeExecution.item</label>
								<div>
									<span [translate]="'fournisseur.formatInterface.modeExecution.' + (formatInterface.typeExecution || 'NON_DEFINI')"></span>
								</div>
							</div>
							<div class="form-group">
								<label translate>fournisseur.formatInterface.mailImport.item</label>
								<div>
									<mat-checkbox name="mailImport" [(ngModel)]="formatInterface.mailImport"><span translate>fournisseur.formatInterface.mailImport.info</span></mat-checkbox>
								</div>
							</div>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': remarque.invalid }">
						<label translate>fournisseur.formatInterface.remarque</label>
						<div>
							<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="formatInterface.remarque" rows="3"></textarea>
						</div>
					</div>
				</fieldset>
			</div>
		</div>
		<span class="pull-right">
			<button mat-stroked-button color="primary" (click)="deleteFormatInterface();" *ngIf="formatInterface?.idInterface && (null | right:'suppression')">
				<span translate>actions.supprimer</span>
			</button>
			<button mat-raised-button color="primary" [disabled]="formatInterfaceForm.invalid" (click)="saveFormatInterface();" *ngIf="null | right:'creation'">
				<span [translate]="'actions.'+(formatInterface?.idInterface ? 'enregistrer' : 'continuer')"></span>
			</button>
		</span>
		<button mat-button *ngIf="formatInterface?.idInterface" [formDetectChanges]="{ doAction: close,isDirty: formatInterfaceForm.dirty }">
			<span translate>actions.fermer</span>
		</button>
	</form>
</div>