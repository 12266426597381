import { AfterContentChecked,ChangeDetectorRef,Component,Input,OnInit } from '@angular/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { filter,first,map } from 'rxjs';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { EntretienContreVisiteAddComponent } from './entretien-contre-visite-add.component';

@Component({
	selector: 'entretien-contre-visite',
	templateUrl: './entretien-contre-visite.component.html'
})
export class EntretienContreVisiteComponent implements AfterContentChecked,OnInit {
	/** Liste des contre-visites **/
	@Input() listeContreVisites: any;

	/** Entretien **/
	@Input() readonly entretien: any;

	/** Résultat **/
	result: { listeContreVisites: any };

	/**
	 * Constructeur
	 */
	constructor(private changeDetectorRef: ChangeDetectorRef,public bsModalRef: BsModalRef<EntretienContreVisiteComponent>,private bsModalService: BsModalService,private translateService: TranslateService) {
		//Binding des méthodes
		this.close = this.close.bind(this);
	}

	/**
	 * Vérification des modifications
	 */
	ngAfterContentChecked() {
		//Détection des changements
		this.changeDetectorRef.detectChanges();
	}

	/**
	 * Initialisation du composant
	 */
	ngOnInit(): void {
		//Initialisation de la liste des contre-visites
		this.listeContreVisites = this.listeContreVisites || [];

		//Vérification de la liste pour l'ajout automatique d'une contre-visite
		if (this.listeContreVisites.length === 0)
			//Création d'une contre-visite
			this.addContreVisite();
	}

	/**
	 * Enregistrement de la liste des contre-visites
	 */
	public saveListeContreVisites() {
		//Définition du résultat
		this.result = {
			listeContreVisites: this.listeContreVisites
		};

		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Fermeture de la liste des contre-visites
	 */
	public close() {
		//Fermeture de la modal
		this.bsModalRef.hide();
	}

	/**
	 * Libellé de la contre-visite
	 */
	getLibelle(contreVisite: any): string {
		let key: string;

		//Vérification du type d'avis
		if (contreVisite.typeAvisControle == 'FAVORABLE')
			//Libellé de la contre-visite
			key = 'entretien.contreVisite.ajoutContreVisite.favorable';
		else if (contreVisite.typeAvisControle == 'DEFAVORABLE')
			//Libellé de la contre-visite
			key = 'entretien.contreVisite.ajoutContreVisite.defavorable';
		else
			//Libellé de la contre-visite
			key = 'entretien.contreVisite.ajoutContreVisite.nonDefini';

		//Traduction du libellé
		return this.translateService.instant(key,{ dateLimite: moment(this.entretien.dateLimiteContreVisite).format('LL') });
	}

	/**
	 * Ajout d'une contre-visite
	 */
	addContreVisite(contreVisite?: any) {
		let bsModalRef: BsModalRef<EntretienContreVisiteAddComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(EntretienContreVisiteAddComponent,{
			initialState: {
				entretien: this.entretien,
				contreVisite: Object.assign({},contreVisite || {})
			},
			class: 'modal-md'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.contreVisite),
			filter(cv => !!cv)
		).subscribe({
			next: (cv: any) => {
				//Vérification de l'existence de la contre-visite
				if (contreVisite)
					//Mise à jour de la contre-visite
					Object.assign(contreVisite,cv);
				else
					//Ajout de la contre-visite
					this.listeContreVisites.push(cv);
			}
		});
	}

	/**
	 * Suppression d'une contre-visite
	 */
	deleteContreVisite(contreVisite: any,index: number) {
		//Suppression de la contre-visite de la liste
		this.listeContreVisites.splice(index,1);
	}

	/**
	 * Vérification de l'autorisation d'ajouter une contre-visite
	 */
	isAddContreVisiteAllowed(): boolean {
		//Retour de la vérification
		return this.listeContreVisites.length === 0 || !this.listeContreVisites.some(cv => cv.typeAvisControle == 'FAVORABLE' || cv.typeAvisControle == 'NON_DEFINI');
	}
}