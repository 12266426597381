<form #entretienContreVisiteForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="modal-header">
		<h4 class="modal-title" translate>entretien.contreVisite.title</h4>
	</div>
	<div class="modal-body">
		<fieldset [disabled]="!(null | right:'creation')">
			<div class="listview lv-bordered local">
				<div class="lv-body">
					<div class="lv-item media" *ngFor="let contreVisite of listeContreVisites; let index = index">
						<div class="pull-left lv-avatar">
							<icon *ngIf="contreVisite.typeAvisControle == 'NON_DEFINI'" name="hourglass_bottom"></icon>
							<icon *ngIf="contreVisite.typeAvisControle == 'FAVORABLE'" name="check"></icon>
							<icon *ngIf="contreVisite.typeAvisControle == 'DEFAVORABLE'" name="close"></icon>
						</div>
						<div class="lv-title">
							<a [routerLink]="[]" (click)="addContreVisite(contreVisite)">{{ getLibelle(contreVisite) }}</a>
						</div>
						<ul class="lv-attrs">
							<li *ngIf="entretien.dateLimiteContreVisite && !contreVisite.dateRealisation"><span translate>entretien.contreVisite.dateLimite</span>&#160;:&#160;<span>{{ entretien.dateLimiteContreVisite | date:'shortDate' }}</span></li>
							<li *ngIf="contreVisite.dateRdv && !contreVisite.dateRealisation"><span translate>entretien.contreVisite.dateRdv</span>&#160;:&#160;<span>{{ contreVisite.dateRdv | date:'shortDate' }}</span></li>
							<li *ngIf="contreVisite.dateRealisation"><span translate>entretien.contreVisite.dateRealisation</span>&#160;:&#160;<span>{{ contreVisite.dateRealisation | date:'shortDate' }}</span></li>
						</ul>
						<div class="lv-actions actions">
							<div class="d-flex align-items-center">
								<button mat-icon-button [tooltip]="'actions.supprimer' | translate" (click)="deleteContreVisite(contreVisite,index)">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
						</div>
					</div>
					<div class="text-center p-10" *ngIf="!listeContreVisites?.length">
						<h5><span translate>liste.empty</span></h5>
					</div>
				</div>
			</div>
			<div class="listview m-t-20" *ngIf="(null | right:'creation') && isAddContreVisiteAllowed()">
				<div class="lv-body">
					<div class="lv-item media text-center clickable lv-item-add" matRipple (click)="addContreVisite();">
						<h5><a [routerLink]="[]" translate>entretien.contreVisite.libelle.ajouterContreVisite</a></h5>
					</div>
				</div>
			</div>
		</fieldset>
	</div>
	<div class="modal-footer">
		<button mat-button (click)="bsModalRef.hide()">
			<span translate>actions.fermer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="entretienContreVisiteForm.invalid" (click)="saveListeContreVisites();" *ngIf="null | right:'creation'">
			<span translate>actions.continuer</span>
		</button>
	</div>
</form>