import { Component,OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ListView,TypeFilter } from 'src/app/domain/common/list-view';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { FormatInterfaceListItemComponent } from './format-interface-list-item.component';
import { FormatInterfaceService } from './format-interface.service';

@Component({
	selector: 'format-interface-list',
	templateUrl: './format-interface-list.component.html'
})
export class FormatInterfaceListComponent implements OnInit {
	/** Données **/
	liste: ListView<any,FormatInterfaceListItemComponent>;

	/**
	 * Constructeur
	 */
	constructor(private translateService: TranslateService,private rightService: RightService,private layoutService: LayoutService,private formatInterfaceService: FormatInterfaceService) { }

	/**
	 * Initialisation
	 */
	ngOnInit() {
		//Définition de la liste
		this.liste = new ListView<any,FormatInterfaceListItemComponent>({
			uri: `/controller/Fournisseur/filtreInterfaces`,
			title: this.translateService.instant('fournisseur.formatInterface.liste.title'),
			component: FormatInterfaceListItemComponent,
			listeFilters: [{
				clef: 'libelle',
				isDefault: true
			},{
				clef: 'reference',
				isDefault: true
			},{
				clef: 'isActif',
				type: TypeFilter.BOOLEAN
			},{
				clef: 'fournisseur.idFournisseur',
				type: TypeFilter.AUTOCOMPLETE,
				autocomplete: {
					type: 'fournisseur'
				},
				title: this.translateService.instant('search.fournisseur')
			},{
				clef: 'typeExecution',
				type: TypeFilter.LONG,
				title: this.translateService.instant('fournisseur.formatInterface.modeExecution.item'),
				listeValues: [{
					code: 'NON_DEFINI',
					libelle: 'fournisseur.formatInterface.modeExecution.NON_DEFINI'
				},{
					code: 'MIDDLEWARE_BETA',
					libelle: 'fournisseur.formatInterface.modeExecution.MIDDLEWARE_BETA'
				},{
					code: 'MIDDLEWARE_STABLE',
					libelle: 'fournisseur.formatInterface.modeExecution.MIDDLEWARE_STABLE'
				}]
			},{
				clef: 'typeFlux',
				type: TypeFilter.LONG,
				title: this.translateService.instant('fournisseur.formatInterface.typeFlux.item'),
				listeValues: this.formatInterfaceService.getListeTypesFlux()
			},{
				clef: 'isMailImport',
				type: TypeFilter.BOOLEAN
			}],
			doMainAction: this.rightService.hasRight(null,'creation') && (() => this.layoutService.goToByUrl('/Fournisseur/loadInterface/0'))
		});
	}
}
