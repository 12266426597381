import { NgModule } from '@angular/core';

import { ShareModule } from 'src/app/share/share.module';
import { ChartListItemComponent } from './chart-list-item.component';
import { ChartListComponent } from './chart-list.component';
import { ChartComponent } from './chart.component';
import { ChartService } from './chart.service';
import { ChartEditComponent } from './chart-edit.component';
import { ChartDataListComponent } from './chart-data-list.component';
import { ChartDataEditComponent } from './chart-data-edit.component';
import { ChartPreviewComponent } from './chart-preview.component';
import { DashboardModule } from 'src/app/components/dashboard/dashboard.module';
import { ChartDefinitionModule } from './definition/chart-definition.module';

@NgModule({
	imports: [ShareModule,DashboardModule,ChartDefinitionModule],
	declarations: [ChartListComponent,ChartListItemComponent,ChartComponent,ChartEditComponent,ChartDataListComponent,ChartDataEditComponent,ChartPreviewComponent],
	providers: [ChartService]
})
export class ChartModule { }