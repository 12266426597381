import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { Observable,filter,first,map } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';

import { Result } from 'src/app/domain/common/http/result';
import { environment } from 'src/environments/environment';
import { EntretienInterventionComponent } from './entretien-intervention.component';
import { EntretienContreVisiteComponent } from './entretien-contre-visite.component';

@Injectable()
export class EntretienService {
	/** Liste des types d'entretien **/
	private listeTypesEntretien: Array<string> = ['CONTROLE_REGLEMENTAIRE','ENTRETIEN','COURANT'];

	/** Liste des types d'avis de contrôle **/
	private listeTypesAvisControle: Array<string> = ['FAVORABLE','DEFAVORABLE'];

	/**
	 * Constructeur
	 */
	constructor(private http: HttpClient,private translateService: TranslateService,private bsModalService: BsModalService) {}

	/**
	 * Récupération de la liste des types d'entretien
	 */
	public getListeTypesEntretien(): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'entretien
		return this.listeTypesEntretien.map(code => ({
			code,
			libelle: this.translateService.instant(`entretien.type.${code}`)
		}));
	}

	/**
	 * Récupération de la liste des types d'avis de contrôle
	 */
	public getListeTypesAvisControle(isWithNotDefined?: boolean): Array<{ code: string,libelle: string }> {
		//Retour de la liste des types d'avis de contrôle
		return [isWithNotDefined && 'NON_DEFINI'].concat(this.listeTypesAvisControle).filter(i => !!i).map(code => ({
			code,
			libelle: this.translateService.instant(`entretien.contreVisite.typeAvisControle.${code}`)
		}));
	}

	/**
	 * Chargement de l'entretien
	 */
	public loadEntretien(idEntretien: any): Observable<Result> {
		//Chargement de l'entretien
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entretien/loadEntretien/${idEntretien}`,null);
	}

	/**
	 * Enregistrement de l'entretien
	 */
	public saveEntretien(entretien: any): Observable<Result> {
		//Enregistrement de l'entretien
		return this.http.put<Result>(`${environment.baseUrl}/controller/Entretien/saveEntretien`,entretien);
	}

	/**
	 * Suppression de l'entretien
	 */
	public deleteEntretien(entretien: any): Observable<Result> {
		//Suppression de l'entretien
		return this.http.delete<Result>(`${environment.baseUrl}/controller/Entretien/deleteEntretien/${entretien.idEntretien}`);
	}

	/**
	 * Récupération de l'identifiant de la facture liée à l'entretien
	 */
	public findIdFactureForEntretien(entretien: any): Observable<Result> {
		//Récupération de l'identifiant de la facture liée à l'entretien
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entretien/findIdFactureForEntretien/${entretien.idEntretien}`,null);
	}

	/**
	 * Enregistrement du plan d'entretien
	 */
	public savePlanEntretien(planEntretien: any): Observable<any> {
		//Enregistrement du plan d'entretien
		return this.http.put<Result>(`${environment.baseUrl}/controller/VehiculeReferentiel/savePlanEntretien`,planEntretien);
	}

	/**
	 * Récupération du dernier entretien lié à un véhicule pour un type, un sous-type et une date donnés
	 */
	public findEntretienByVehiculeTypeAndDatePrevisionnelle(vehicule: any,typeEntretien: 'CONTROLE_REGLEMENTAIRE' | 'ENTRETIEN' | 'COURANT',date: Date,sousType?: 'TECHNIQUE' | 'ANTIPOLLUTION'): Observable<Result> {
		//Récupération du dernier entretien lié à un véhicule pour un type, un sous-type et une date donnés
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entretien/findEntretienByVehiculeTypeAndDatePrevisionnelle/${vehicule.idVehicule}/${typeEntretien}/${date}${sousType ? `/${sousType}` : ''}`,null);
	}

	/**
	 * Récupération du dernier entretien lié à un véhicule pour un type, un sous-type et une date donnés
 	*/
	public findEntretienByVehiculeTypeAndDateRealisation(vehicule: any,typeEntretien: 'CONTROLE_REGLEMENTAIRE' | 'ENTRETIEN' | 'COURANT',date: Date,sousType?: 'TECHNIQUE' | 'ANTIPOLLUTION'): Observable<Result> {
		//Récupération du dernier entretien lié à un véhicule pour un type, un sous-type et une date donnés
		return this.http.post<Result>(`${environment.baseUrl}/controller/Entretien/findEntretienByVehiculeTypeAndDateRealisation/${vehicule.idVehicule}/${typeEntretien}/${date}${sousType ? `/${sousType}` : ''}`,null);
	}

	/**
	 * Ouverture de la popup indiquant la liste des interventions
	 */
	public showListeInterventions(entretien: any): Observable<Array<any>> {
		let bsModalRef: BsModalRef<EntretienInterventionComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(EntretienInterventionComponent,{
			initialState: {
				listeLinksVehiculePlanEntretienDetail: cloneDeep(entretien.listeLinksVehiculePlanEntretienDetail) || [],
				entretien: entretien
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.listeLinksVehiculePlanEntretienDetail),
			filter(listeLinksVehiculePlanEntretienDetail => !!listeLinksVehiculePlanEntretienDetail)
		);
	}

	/**
	 * Affichage de la liste des contre-visites
	 */
	public showListeContreVisites(entretien: any): Observable<Array<any>> {
		let bsModalRef: BsModalRef<EntretienContreVisiteComponent>;

		//Affichage de la popup
		bsModalRef = this.bsModalService.show(EntretienContreVisiteComponent,{
			initialState: {
				listeContreVisites: cloneDeep(entretien.listeContreVisites) || [],
				entretien
			},
			class: 'modal-lg'
		});

		//Retour du résultat
		return bsModalRef.onHidden.pipe(
			first(),
			map(() => bsModalRef.content?.result?.listeContreVisites),
			filter(listeContreVisites => !!listeContreVisites)
		);
	}

	/**
	 * Récupération de l'état de contre-visite de l'entretien
	 */
	retrieveStateForEntretien(entretien: any): 'DEFAVORABLE' | 'FAVORABLE' | 'PENDING' {
		let etatContreVisite: 'DEFAVORABLE' | 'FAVORABLE' | 'PENDING';

		//Initialisation de l'état de contre-visite
		etatContreVisite = 'FAVORABLE';

		//Vérification du type d'entretien
		if (entretien?.type == 'CONTROLE_REGLEMENTAIRE' && entretien.typeAvisControle == 'DEFAVORABLE' && !entretien.listeContreVisites?.some(cv => cv.typeAvisControle == 'FAVORABLE') && entretien.dateLimiteContreVisite)
			//Vérification de la date limite de la contre-visite
			etatContreVisite = moment(entretien.dateLimiteContreVisite).isAfter(moment.now()) ? 'PENDING' : 'DEFAVORABLE';

		return etatContreVisite;
	}
}