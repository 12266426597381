<form #chartGraphiqueForm="ngForm" autocomplete="off" (keydown.enter)="$event.preventDefault()">
	<div class="card detail">
		<div class="card-body card-padding">
			<chart-graphique-content #chartGraphiqueContent [chart]="chart" [indexDefinition]="indexDefinition" [definition]="definition" [options]="options" [formMarkAsDirty]="chartGraphiqueForm.form.markAsDirty"></chart-graphique-content>
		</div>
	</div>
	<span class="pull-right">
		<button mat-stroked-button color="primary" (click)="deleteDefinition(close);" *ngIf="definition?.idDefinition && (null | right:'creation') && indexDefinition == 1">
			<span translate>actions.supprimer</span>
		</button>
		<button mat-raised-button color="primary" [disabled]="options.mode == 'SELECTION' || definition.type == 'NON_DEFINI' || chartGraphiqueForm.invalid" *ngIf="null | right:'creation'" (click)="saveDefinition()">
			<span translate>actions.enregistrer</span>
		</button>
	</span>
	<button mat-button [formDetectChanges]="{ doAction: close,isDirty: chartGraphiqueForm.dirty }">
		<span translate>actions.fermer</span>
	</button>
</form>