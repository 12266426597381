<fieldset ngModelGroup="entretienContent" #entretienContent="ngModelGroup" [disabled]="!(TypeDroit.ENTRETIEN | right:'creation') || entretien.readOnly">
	<div class="card m-b-0" [ngClass]="{ 'detail': source != 'ENTRETIEN' }">
		<div class="card-header" *ngIf="source != 'ENTRETIEN'">
			<h2 class="card-title" translate>vehicule.elements.entretien.title</h2>
		</div>
		<div class="card-body card-padding p-b-0">
			<div class="alert alert-info" *ngIf="entretien.readOnly && source != 'CONDUCTEUR'">
				<icon name="info"></icon>
				<div>
					<strong translate>entretien.alertes.nonModifiable.message</strong>
					<div translate>entretien.alertes.nonModifiable.description</div>
				</div>
			</div>
			<div class="alert alert-info" *ngIf="entretien?.listeContreVisites?.length && entretienService.retrieveStateForEntretien(entretien) == 'PENDING'">
				<icon name="info"></icon>
				<div>
					<strong translate>entretien.contreVisite.alerte.pending.title</strong>
					<div>{{ getLibelleAlerteForContreVisite() }}</div>
				</div>
			</div>
			<div class="alert alert-info" *ngIf="entretien?.previsionnel && getNbInterventionsPrevues() as nbInterventionsPrevues">
				<icon name="info"></icon>
				<div>
					<strong [translateParams]="{ count: nbInterventionsPrevues }" [translate]="'entretien.alertes.previsionnel.title' | pluralTranslate:nbInterventionsPrevues"></strong>
					<div [translate]="'entretien.alertes.previsionnel.description' | pluralTranslate:nbInterventionsPrevues"></div>
				</div>
				<button mat-raised-button color="primary" (click)="showListeInterventions()">
					<span translate>actions.continuer</span>
				</button>
			</div>
			<div class="alert alert-info" *ngIf="user?.type == 'VIRTUAL' && entretien.vehicule && lastCompteur">
				<icon name="info"></icon>
				<div>
					<strong translate>entretien.alertes.lastCompteur.message</strong>
					<div translate [translateParams]="{ dateReleve: (lastCompteur.dateReleve | date:'shortDate'),vehicule: lastCompteur.vehicule.reference,releveCompteur: (lastCompteur.releveCompteur | number:'.2-2'),unite: (lastCompteur.vehicule.unite?.libelleCourt || user?.unite?.libelleCourt || '') }">entretien.alertes.lastCompteur.description</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group" [ngClass]="{ 'has-error': entretienContent.control?.controls['vehicule']?.invalid }" *ngIf="source != 'VEHICULE'">
						<label translate>entretien.vehicule</label>
						<div>
							<ng-container *ngIf="!entretien.vehicule && user?.type != 'VIRTUAL'">
								<autocomplete name="vehicule" type="vehicule" filter="VEHICULE_FONCTION_SERVICE" #vehicule="ngModel" [(ngModel)]="entretien.vehicule" required (ngModelChange)="onVehiculeChange()"></autocomplete>
							</ng-container>
							<div *ngIf="entretien.vehicule">
								<mat-chip-listbox>
									<mat-chip [ngClass]="{ 'clickable': (TypeDroit.ADMIN_VEHICULE | right:'consultation') }" (click)="user?.type != 'VIRTUAL' && rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && goToVehicule()">
										<span>{{ entretien.vehicule | display:'vehicule' }}</span>
										<button matChipRemove (click)="entretien.vehicule = null" *ngIf="(TypeDroit.ENTRETIEN | right:'creation') && (entretien.type == 'COURANT' || !entretien.idEntretien) && !entretien.readOnly && user?.type != 'VIRTUAL' && source != 'CONDUCTEUR'">
											<mat-icon>cancel</mat-icon>
										</button>
									</mat-chip>
									<span *ngIf="user?.type != 'VIRTUAL'">
										<icon name="replay_30" class="clickable pull-right icon-hc-2x" (click)="vehiculeService.showListeImmatriculations(entretien.vehicule.idVehicule)" *ngIf="entretien.vehicule?.listeOldReferences?.length"></icon>
									</span>
								</mat-chip-listbox>
							</div>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': type.invalid }">
						<label translate>entretien.type.item</label>
						<div>
							<selectpicker name="type" #type="ngModel" [(ngModel)]="entretien.type" [disabled]="entretien.idEntretien || entretien.vehicule?.typeVehicule == 'ENGIN'" (ngModelChange)="onTypeChange(entretien.type)" required>
								<mat-option *ngFor="let type of listeTypesEntretien" [value]="type.code">{{ type.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': sousType.invalid }" *ngIf="entretien.type == 'CONTROLE_REGLEMENTAIRE'">
						<label translate>vehicule.controleReglementaire.sousType</label>
						<div>
							<selectpicker name="sousType" #sousType="ngModel" [(ngModel)]="entretien.sousType" [disabled]="!rightService.isRoot(true) || !!entretien?.idEntretien" required>
								<mat-option *ngFor="let sousType of listeSousTypes" [value]="sousType.code">{{ sousType.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" *ngIf="entretien.type == 'COURANT'">
						<label translate>entretien.typeEntretien</label>
						<div>
							<autocomplete name="typeEntretien" type="typeEntretien" #typeEntretien="ngModel" [(ngModel)]="entretien.typeEntretienItem" [disabled]="user?.type == 'VIRTUAL' && savedEntretien.typeEntretienItem" [isCreationDisabled]="user?.type == 'VIRTUAL'"></autocomplete>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': datePrevisionnelle.invalid }" *ngIf="entretien.datePrevisionnelle || entretien.type == 'COURANT' && (!entretien.idEntretien || entretien.previsionnel)">
						<label translate>entretien.datePrevisionnelle</label>
						<div class="half" [ngClass]="{ 'input-group': entretien.dateLastEntretien && entretien.lastCompteur || entretien.compteurPrevisionnel || !entretien.dateLastEntretien && entretien.typePrevisionEntretien != 'NON_DEFINI' }">
							<input type="text" name="datePrevisionnelle" class="form-control" #datePrevisionnelle="ngModel" [(ngModel)]="entretien.datePrevisionnelle" datePicker [storeDate]="true" [disabled]="entretien.type != 'COURANT' || !entretien.previsionnel && entretien.idEntretien || user?.type == 'VIRTUAL'" [required]="entretien.type == 'COURANT' && !entretien.dateRealisation"/>
							<div class="input-group-addon" *ngIf="entretien.dateLastEntretien && entretien.lastCompteur || entretien.compteurPrevisionnel || !entretien.dateLastEntretien && entretien.typePrevisionEntretien != 'NON_DEFINI'">
								<ng-template #compteurTooltipTemplate>
									<div style="text-align: left;">
										<div *ngIf="!entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DUREE' && entretien.vehicule.planEntretien.duree"><strong translate>entretien.planEntretienDuree.infoPremier</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.duree | number }}</span>&#160;<span translate>duree.mois</span></div>
										<div *ngIf="entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DUREE' && entretien.vehicule.planEntretien.duree"><strong translate>entretien.planEntretienDuree.info</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.duree | number }}</span>&#160;<span translate>duree.mois</span></div>
										<div *ngIf="!entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DISTANCE' && entretien.vehicule.planEntretien.distance"><strong translate>entretien.planEntretienDistance.infoPremier</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.distance | number }}</span>&#160;<span>{{ entretien.vehicule.planEntretien.unite.libelleCourt }}</span></div>
										<div *ngIf="entretien.dateLastEntretien && entretien.typePrevisionEntretien == 'DISTANCE' && entretien.vehicule.planEntretien.distance"><strong translate>entretien.planEntretienDistance.info</strong>&#160;:&#160;<span>{{ entretien.vehicule.planEntretien.distance | number }}</span>&#160;<span>{{ entretien.vehicule.planEntretien.unite.libelleCourt }}</span></div>
										<div *ngIf="entretien.dateLastEntretien"><strong translate>entretien.dateLastEntretien</strong>&#160;:&#160;<span>{{ entretien.dateLastEntretien | date:'shortDate' }}</span></div>
										<div *ngIf="entretien.lastCompteur"><strong translate>entretien.lastCompteur</strong>&#160;:&#160;<span>{{ entretien.lastCompteur + ' ' + (entretien.vehicule.unite?.libelleCourt || user?.unite?.libelleCourt || '') }}</span></div>
										<div *ngIf="entretien.dateLastEntretien && entretien.compteurPrevisionnel"><strong translate>entretien.compteurPrevisionnel</strong>&#160;:&#160;<span>{{ (entretien.compteurPrevisionnel | number) + ' ' + (entretien.vehicule.unite.libelleCourt || user?.unite?.libelleCourt || '') }}</span></div>
									</div>
								</ng-template>
								<icon name="info_outline" class="icon-hc-2x" [tooltip]="compteurTooltipTemplate"></icon>
							</div>
						</div>
					</div>
					<div class="form-group" *ngIf="(entretien.idEntretien && entretien.extension && (entretien.extension.userAffecte || entretien.extension.vehiculeImmobilise) && source != 'CONDUCTEUR') && (user?.type != 'VIRTUAL' || user?.type == 'VIRTUAL' && entretien.type == 'CONTROLE_REGLEMENTAIRE')">
						<label translate>entretien.userAffecte</label>
						<div>
							<span *ngIf="entretien.extension.userAffecte">{{ entretien.extension.userAffecte | display:'user' }}</span>
							<span *ngIf="entretien.extension.vehiculeImmobilise" translate>entretien.vehiculeImmobilise</span>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': dateRealisation.invalid }">
						<label translate>entretien.dateRealisation</label>
						<div class="half">
							<input type="text" name="dateRealisation" class="form-control" #dateRealisation="ngModel" [(ngModel)]="entretien.dateRealisation" (ngModelChange)="onDateRealisationChange($event)" datePicker maxDate="today" [storeDate]="true" [required]="!entretien.datePrevisionnelle || !entretien.previsionnel && entretien.type == 'ENTRETIEN' || savedEntretien.dateRealisation || hasInterventionRealisee() || hasContreVisite()" [disabled]="hasContreVisite()"/>
						</div>
					</div>
					<ng-container *ngIf="entretien?.type == 'CONTROLE_REGLEMENTAIRE' && rightService.isRoot(true) && entretien.dateRealisation">
						<div class="form-group" [ngClass]="{ 'has-error': typeAvisControleField.invalid || typeAvisControleCheck.invalid }">
							<label translate>entretien.contreVisite.typeAvisControle.item</label>
							<div class="half">
								<selectpicker name="typeAvisControle" #typeAvisControleField="ngModel" [(ngModel)]="entretien.typeAvisControle" [required]="true" [disabled]="!!entretien.listeContreVisites?.length" (ngModelChange)="computeDateLimiteForContreVisite($event)">
									<mat-option *ngFor="let typeAvisControle of listeTypesAvisControle" [value]="typeAvisControle.code">{{ typeAvisControle.libelle }}</mat-option>
								</selectpicker>
								<input type="hidden" name="typeAvisControleCheck" #typeAvisControleCheck="ngModel" [(ngModel)]="entretien.typeAvisControleCheck" [required]="entretien.typeAvisControle == 'NON_DEFINI'"/>
							</div>
						</div>
						<div class="form-group" *ngIf="entretien?.typeAvisControle == 'DEFAVORABLE' || entretien?.listeContreVisites?.length" [ngClass]="{ 'has-error': listeContreVisites.invalid }">
							<label translate>entretien.contreVisite.item</label>
							<div>
								<a [routerLink]="[]" (click)="showListeContreVisites()">{{ getLibelleForListeContreVisites() }}</a>
								<input type="hidden" name="listeContreVisites" #listeContreVisites="ngModel" [(ngModel)]="entretien.listeContreVisites" required/>
							</div>
						</div>
					</ng-container>
					<div class="form-group" [ngClass]="{ 'has-error': entretienContent.control?.controls['compteurField']?.invalid }" *ngIf="entretien.type != 'COURANT' || entretien.dateRealisation">
						<label translate>entretien.compteur</label>
						<div class="input-group">
							<input *ngIf="!entretien.vehicule || entretien.vehicule.uniteUsage == 'DISTANCE'" type="text" name="compteurField" class="form-control" #releveCompteurField="ngModel" [(ngModel)]="entretien.compteur" nNumber min="0" [required]="entretien.previsionnel && entretien.dateRealisation || savedEntretien.compteur || entretien.type == 'ENTRETIEN' && entretien.dateRealisation">
							<input *ngIf="entretien.vehicule && entretien.vehicule.uniteUsage == 'TEMPS'" type="text" name="compteurField" class="form-control" #releveCompteurField="ngModel" [(ngModel)]="entretien.compteurHoraire" nNumber min="0" [required]="entretien.previsionnel && entretien.dateRealisation || savedEntretien.compteur || entretien.type == 'ENTRETIEN' && entretien.dateRealisation"/>
							<div class="input-group-addon no-border">
								<span *ngIf="!entretien.vehicule || entretien.vehicule.uniteUsage == 'DISTANCE'">{{ entretien.vehicule?.unite?.libelleCourt || user?.unite?.libelleCourt || '' }}</span>
								<span *ngIf="entretien.vehicule?.uniteUsage == 'TEMPS'" translate>duree.heure.libelleCourt</span>
							</div>
							<div class="input-group-addon">
								<icon name="replay_30" class="icon-hc-2x clickable" *ngIf="entretien.vehicule && user?.type != 'VIRTUAL'" (click)="vehiculeService.showListeCompteurs(entretien.vehicule)"></icon>
							</div>
						</div>
					</div>
					<div class="form-group" *ngIf="entretien?.type == 'ENTRETIEN'">
						<label translate>entretien.intervention.item</label>
						<div>
							<a [routerLink]="[]" (click)="showListeInterventions()">{{ getLibelleForListeRevisions() }}</a>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group" *ngIf="entretien.type == 'CONTROLE_REGLEMENTAIRE'">
						<label translate>entretien.dateDebut</label>
						<div class="half">
							<input type="text" name="dateDebut" class="form-control" #dateDebut="ngModel" [(ngModel)]="entretien.dateDebut" datePicker [storeDate]="true" [disabled]="true"/>
						</div>
					</div>
					<div class="form-group">
						<label translate>entretien.dateRdv</label>
						<div class="half">
							<input type="text" name="dateRdv" class="form-control" #dateRdv="ngModel" [(ngModel)]="entretien.dateRdv" datePicker format="datetime"/>
						</div>
					</div>
					<div class="form-group">
						<label translate>entretien.lieuRdv</label>
						<div>
							<adresse name="lieu" #lieu="ngModel" [(ngModel)]="entretien.lieu"></adresse>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': entretienContent.control?.controls['source']?.invalid }" *ngIf="user?.type != 'VIRTUAL' && (entretien.type == 'COURANT' && entretien.dateRealisation || entretien.type != 'COURANT')">
						<label translate>entretien.source.item</label>
						<div>
							<span *ngIf="entretien.typeOrigineSaisie == 'MOBILE'" [translate]="'entretien.source.'+entretien.typeSource"></span>
							<selectpicker *ngIf="entretien.typeOrigineSaisie != 'MOBILE'" name="source" #source="ngModel" [(ngModel)]="entretien.typeSource" required>
								<mat-option *ngFor="let typeSource of listeTypesSource" [value]="typeSource"><span [translate]="'entretien.source.'+typeSource"></span></mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': fournisseur.invalid }">
						<label translate>entretien.fournisseur</label>
						<div>
							<autocomplete name="fournisseur" type="fournisseur" #fournisseur="ngModel" [(ngModel)]="entretien.fournisseur" [required]="entretien.typeSource == 'FOURNISSEUR'"></autocomplete>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group">
				<label translate>entretien.piecesJointes</label>
				<div>
					<div [attachment]="entretien" [typeAttachment]="TypeAttachment.ENTRETIEN" [listeTypesDroit]="[TypeDroit.ENTRETIEN]"></div>
				</div>
			</div>
			<div class="form-group">
				<label translate>entretien.remarque</label>
				<div>
					<textarea class="form-control" name="remarque" #remarque="ngModel" [(ngModel)]="entretien.remarque" rows="3"></textarea>
				</div>
			</div>
		</div>
	</div>

	<div class="card" [ngClass]="{ 'detail': source != 'ENTRETIEN' }">
		<data-customization entite="com.notilus.data.vehicule.Entretien" [object]="entretien" [disabled]="!(null | right:'creation')" [isSubItem]="source != 'ENTRETIEN'"></data-customization>
	</div>
</fieldset>