<div class="pull-left lv-avatar">{{ data?.reference?.substring(0,3)?.toUpperCase() }}</div>
<div class="media-body">
	<div class="lv-title">
		<a [routerLink]="[]" (click)="extraOptions?.addContratCRM?.(data)">{{ data | display:'contratCRM' }}</a>
	</div>
	<ul class="lv-attrs" *ngIf="data.idContrat">
		<li><span translate>tenant.clientCRM.reference</span>&#160;:&#160;<span>{{ data.clientCRM.reference }}</span></li>
		<li *ngIf="data.articleCRM"><span translate>tenant.contratCRM.articleCRM</span>&#160;:&#160;<span [translate]="data.articleCRM.libelle"></span></li>
		<li *ngIf="data.dateEffet"><span translate>tenant.contratCRM.dateEffet</span>&#160;:&#160;{{ data.dateEffet | date:'shortDate' }}</li>
	</ul>
	<div class="lv-actions actions dropdown" *ngIf="TypeDroit.ADMIN_CONTRAT_CRM | right:'consultation'">
		<a [routerLink]="[]" data-toggle="dropdown"><icon name="more_vert"></icon></a>
		<ul class="dropdown-menu dropdown-menu-right">
			<li><a [routerLink]="[]" (click)="goToContratCRM();"><span translate>tenant.actions.accederContratCRM</span></a></li>
		</ul>
	</div>
</div>