
<fieldset ngModelGroup="chartGraphiqueContent" #chartGraphiqueContent="ngModelGroup" [disabled]="!(TypeDroit.ADMIN_CHART | right:'creation') || options.mode == 'CONSULTATION'">
	<ng-container *ngIf="options.mode == 'SELECTION'">
		<h4 translate>{{ 'chart.definition.' + (indexDefinition == 0 ? 'principal' : 'secondaire') + '.title.selection' }}</h4>
		<div class="chart-selector global">
			<div *ngFor="let typeGraphique of listeTypesGraphique" class="chart-type" [ngClass]="{ 'selected': definition.type == typeGraphique.type }" (click)="selectTypeGraphique(typeGraphique);">
				<div class="graph {{ typeGraphique.type | lowercase }}"></div>
				<span class="title" [translate]="'chart.typeGraphique.' + typeGraphique.type"></span>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="options.mode == 'EDITION' || options.mode == 'CONSULTATION'">
		<h4 translate>{{ 'chart.definition.' + (indexDefinition == 0 ? 'principal' : 'secondaire') + '.title.edition' }}</h4>
		<div class="row">
			<div class="col-md-4 chart-selector">
				<div class="chart-type" [ngClass]="{ 'forbidden': !rightService.hasRight(null,'creation') }" (click)="rightService.hasRight(null,'creation') && (options.mode = 'SELECTION');">
					<div class="graph {{ selectedTypeGraphique.type | lowercase }}"></div>
					<span class="title" [translate]="'chart.typeGraphique.' + selectedTypeGraphique.type"></span>
				</div>
				<div>
					<a [routerLink]="[]" *ngIf="null | right:'creation'" (click)="options.mode = 'SELECTION';" translate>chart.definition.modifierTypeGraphique</a>
				</div>
			</div>
			<div class="col-md-8">
				<fieldset [disabled]="!(null | right:'creation')">
					<div class="form-group" *ngIf="selectedTypeGraphique.listeSubTypes?.length > 0">
						<label translate>chart.definition.affichageDonnees</label>
						<div *ngIf="options.mode != 'CONSULTATION'">
							<mat-button-toggle-group [value]="definition.subType" (change)="$event.value != definition.subType && formMarkAsDirty?.(); selectSubType($event.value);" name="subType">
								<mat-button-toggle *ngFor="let subType of selectedTypeGraphique.listeSubTypes" [value]="subType"><span [translate]="'chart.subType.' + subType"></span></mat-button-toggle>
							</mat-button-toggle-group>
						</div>
						<div *ngIf="options.mode == 'CONSULTATION'">
							<span [translate]="'chart.subType.' + definition.subType"></span>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': cle1.invalid }" *ngIf="selectedTypeGraphique.type != 'KPI'">
						<label>{{ ('chart.definition.cle' | translate) + (definition.subType != 'NON_DEFINI' ? ' (1)' : '') }}</label>
						<selectpicker name="cle1" #cle1="ngModel" [(ngModel)]="definition.listeCles[0].cle" (ngModelChange)="onCleChange($event,0)" required>
							<mat-option *ngFor="let field of listeFields" [value]="field.name">{{ field.libelle }}</mat-option>
						</selectpicker>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': typeGroupement1.invalid }" *ngIf="selectedCle1?.listeGroupements?.length">
						<label>{{ ('chart.definition.groupement' | translate) + (definition.subType != 'NON_DEFINI' ? ' (1)' : '') }}</label>
						<div>
							<selectpicker name="typeGroupement1" #typeGroupement1="ngModel" [(ngModel)]="definition.listeCles[0].typeGroupement">
								<mat-option *ngFor="let groupement of selectedCle1.listeGroupements" [value]="groupement"><span [translate]="'rule.groupement.' + groupement"></span></mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': cle2.invalid }" *ngIf="selectedTypeGraphique.type != 'KPI' && selectedTypeGraphique.listeSubTypes?.length && definition.subType != 'NON_DEFINI'">
						<label>{{ ('chart.definition.cle' | translate) + ' (2)' }}</label>
						<div>
							<selectpicker name="cle2" #cle2="ngModel" [(ngModel)]="definition.listeCles[1].cle" (ngModelChange)="onCleChange($event,1)" required>
								<mat-option *ngFor="let field of listeFields" [value]="field.name">{{ field.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': typeGroupement2.invalid }" *ngIf="selectedCle2?.listeGroupements?.length">
						<label>{{ ('chart.definition.groupement' | translate) + ' (2)' }}</label>
						<div>
							<selectpicker name="typeGroupement2" #typeGroupement2="ngModel" [(ngModel)]="definition.listeCles[1].typeGroupement">
								<mat-option *ngFor="let groupement of selectedCle2.listeGroupements" [value]="groupement"><span [translate]="'rule.groupement.' + groupement"></span></mat-option>
							</selectpicker>
						</div>
					</div>
					<hr *ngIf="selectedTypeGraphique.type != 'KPI'"/>
					<div class="form-group" [ngClass]="{ 'has-error': typeAgregation.invalid }">
						<div class="half">
							<label translate>chart.definition.agregation</label>
							<selectpicker name="typeAgregation" #typeAgregation="ngModel" [(ngModel)]="definition.typeAgregation" (ngModelChange)="onTypeAgregationChange()" required>
								<mat-option *ngFor="let typeAgregation of listeTypesAgregation" [value]="typeAgregation.code">{{ typeAgregation.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<div class="form-group" [ngClass]="{ 'has-error': valueField.invalid }">
						<div>
							<label translate>chart.definition.valeur</label>
							<selectpicker name="valueField" #valueField="ngModel" [(ngModel)]="definition.value" required>
								<mat-option *ngFor="let field of getListeFieldsForAgregation()" [value]="field.name">{{ field.libelle }}</mat-option>
							</selectpicker>
						</div>
					</div>
					<hr/>
					<div class="d-flex gap-2">
						<div class="form-group flex-1" [ngClass]="{ 'has-error': typeSort.invalid || typeOrder.invalid }">
							<label translate>chart.definition.tri</label>
							<div>
								<selectpicker class="extend" name="typeSort" #typeSort="ngModel" [(ngModel)]="definition.typeSort" required>
									<mat-option *ngFor="let typeSort of listeTypesSort" [value]="typeSort.code">{{ typeSort.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
						<div class="form-group flex-1" [ngClass]="{ 'has-error': typeSort.invalid || typeOrder.invalid }">
							<label translate>chart.definition.ordre</label>
							<div>
								<selectpicker class="extend" name="typeOrder" #typeOrder="ngModel" [(ngModel)]="definition.typeOrder" required>
									<mat-option *ngFor="let typeOrder of listeTypesOrder" [value]="typeOrder.code">{{ typeOrder.libelle }}</mat-option>
								</selectpicker>
							</div>
						</div>
					</div>
					<ng-container *ngIf="selectedTypeGraphique.type == 'KPI' && options.mode != 'CONSULTATION'">
						<hr/>
						<div class="form-group chart-icone-selector">
							<label translate>chart.definition.icone</label>
							<div>
								<div class="icones-container">
									<div class="icone clickable" [ngClass]="{ 'selected':  definition.icone == icone.reference }" *ngFor="let icone of listeIcones" matRipple (click)="selectIcone(icone.reference)">
										<icon [name]="icone.reference"></icon>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</fieldset>
			</div>
		</div>
		<div *ngIf="selectedTypeGraphique.listeOptions?.length && options.mode != 'CONSULTATION'" class="p-t-20">
			<h4 translate>chart.definition.options</h4>
			<div class="chart-selector">
				<div class="chart-container">
					<span *ngFor="let option of selectedTypeGraphique.listeOptions" class="chart-type option" [ngClass]="{ 'selected': isOptionSelected(option),'forbidden': !rightService.hasRight(null,'creation') }" (click)="rightService.hasRight(null,'creation') && toggleOption(option);">
						<div class="graph option {{ option | lowercase }}"></div>
						<span class="title" [translate]="'chart.option.' + option"></span>
					</span>
				</div>
			</div>
		</div>
	</ng-container>
</fieldset>
