import { TranslateService } from '@ngx-translate/core';

import { Filter } from 'src/app/domain/common/list-view';
import { AutocompleteOptions } from 'src/app/share/components/autocomplete/autocomplete';

/**
 * Options des templates d'import
 */
export default class ImportTemplateOptions implements AutocompleteOptions {
	/**
	 * Titre
	 */
	title = 'autocomplete.importTemplate';

	/**
	 * URL
	 */
	url() { return `/controller/ImportTemplate/filtreImportTemplates`; }

	/**
	 * Affichage d'un élément
	 */
	displayItem(item: any,translateService: TranslateService) { return item ? `${item.libelle} (${translateService.instant('connecteur.import.typeMode.'+item.typeMode)} - ${translateService.instant('connecteur.import.entite.' + item.className?.split('.').pop())})` : '' }

	/**
	 * Affichage de l'avatar
	 */
	displayAvatar(item: any) { return item?.libelle?.substring(0,3)?.toUpperCase() || ''; }

	/**
	 * Type de la classe
	 */
	type = 'com.notilus.data.importTemplate.ImportTemplate';

	/**
	 * Récupération du nom de l'identifiant
	 */
	getKeyFieldName() { return 'idTemplate'; }

	/**
	 * Filtres disponibles
	 */
	listeFilters(): Array<Filter> {
		//Retour de la liste des filtres
		return [{
			clef: 'libelle',
			isDefault: true
		},{
			clef: 'email',
			isDefault: true
		}];
	}
}