import { DatePipe } from '@angular/common';
import { Component,OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject,combineLatest } from 'rxjs';
import { filter,first,switchMap } from 'rxjs/operators';

import { AppState } from 'src/app/domain/appstate';
import { LinksOwningEntity } from 'src/app/domain/attachment/attachment';
import { TypeAttachment } from 'src/app/domain/attachment/type-attachment';
import { TypeAction } from 'src/app/domain/common/complex-page/action';
import { Options } from 'src/app/domain/common/complex-page/options';
import { Result,TypeCodeErreur } from 'src/app/domain/common/http/result';
import { IEntity } from 'src/app/domain/entity/entity';
import { TypeDroit } from 'src/app/domain/security/right';
import { User } from 'src/app/domain/user/user';
import { TypeVehicule } from 'src/app/domain/vehicule/vehicule';
import { AttachmentService } from 'src/app/share/components/attachment/attachment.service';
import { ComplexPageComponent } from 'src/app/share/components/complex-page/complex-page.component';
import { ConfirmService } from 'src/app/share/components/confirmation/confirm.service';
import { NotificationService } from 'src/app/share/components/notification/notification.service';
import { PageContentService } from 'src/app/share/components/page-content/page-content.service';
import { LayoutService } from 'src/app/share/layout/layout.service';
import { CapitalizeFirstPipe } from 'src/app/share/pipe/capitalize-first/capitalize-first.pipe';
import { DisplayPipe } from 'src/app/share/pipe/display/display.pipe';
import { RightService } from 'src/app/share/pipe/right/right.service';
import { EntretienService } from './entretien/entretien.service';
import { VehiculeAffectationEditComponent } from './vehicule-affectation-edit.component';
import { VehiculeAffectationListComponent } from './vehicule-affectation-list.component';
import { VehiculeAmenagementEditComponent } from './vehicule-amenagement-edit.component';
import { VehiculeAmenagementListComponent } from './vehicule-amenagement-list.component';
import { VehiculeContratFinancementEditComponent } from './vehicule-contrat-financement-edit.component';
import { VehiculeContratFinancementListComponent } from './vehicule-contrat-financement-list.component';
import { VehiculeEditComponent } from './vehicule-edit.component';
import { VehiculeEntretienEditComponent } from './vehicule-entretien-edit.component';
import { VehiculeEntretienListComponent } from './vehicule-entretien-list.component';
import { VehiculeEquipementEditComponent } from './vehicule-equipement-edit.component';
import { VehiculeEquipementListComponent } from './vehicule-equipement-list.component';
import { VehiculeEtatLieuxEditComponent } from './vehicule-etat-lieux-edit.component';
import { VehiculeEtatLieuxListComponent } from './vehicule-etat-lieux-list.component';
import { VehiculeFactureDetailListComponent } from './vehicule-facture-detail-list.component';
import { VehiculeImputationEditComponent } from './vehicule-imputation-edit.component';
import { VehiculeImputationListComponent } from './vehicule-imputation-list.component';
import { VehiculeInfractionListComponent } from './vehicule-infraction-list.component';
import { VehiculeReleveCompteurEditComponent } from './vehicule-releve-compteur-edit.component';
import { VehiculeReleveCompteurListComponent } from './vehicule-releve-compteur-list.component';
import { VehiculeReleveEnergieEditComponent } from './vehicule-releve-energie-edit.component';
import { VehiculeReleveEnergieListComponent } from './vehicule-releve-energie-list.component';
import { VehiculeSinistreEditComponent } from './vehicule-sinistre-edit.component';
import { VehiculeSinistreListComponent } from './vehicule-sinistre-list.component';
import { VehiculeService } from './vehicule.service';

@Component({
	selector: 'vehicule',
	templateUrl: './vehicule.component.html'
})
export class VehiculeComponent implements OnInit,IEntity {
	/** Options de la page complexe **/
	public options: Options;

	/** Véhicule courant **/
	public vehicule: any;

	/** Configuration du pays **/
	public configurationPays: any;

	/** Utilisateur courant **/
	public user: User;

	/** Utilisateur d'origine **/
	public userOrigine: User;

	/** Résumé */
	private resume: { nbSinistres: number,nbContraventions: number,nbImputations: number,nbAffectations: number,nbRelevesCompteur: number,nbRelevesCarburant: number,nbContratsFinancement: number,nbDetailsFacture: number,nbAffectationsEquipement: number,nbEntretiens: number,nbAmenagements: number,nbEtatsLieux: number,nbInfractions: number } = {
		nbSinistres: 0,
		nbContraventions: 0,
		nbImputations: 0,
		nbAffectations: 0,
		nbRelevesCompteur: 0,
		nbRelevesCarburant: 0,
		nbContratsFinancement: 0,
		nbDetailsFacture: 0,
		nbAffectationsEquipement: 0,
		nbEntretiens: 0,
		nbAmenagements: 0,
		nbEtatsLieux: 0,
		nbInfractions: 0
	}

	/** Demande de véhicule en cours **/
	private demandeVehicule: any;

	/** Liste des immatriculations du modèle **/
	private listeImmatriculationModeles: Array<any>;

	/** Page complexe **/
	@ViewChild('complexPage') complexPage: ComplexPageComponent;

	/** Type d'attachment **/
	typeAttachment: TypeAttachment = TypeAttachment.VEHICULE;

	/**
	 * Constructeur
	 */
	constructor(private vehiculeService: VehiculeService,private attachmentService: AttachmentService,private toastrService: ToastrService,private pageContentService: PageContentService,private confirmService: ConfirmService,private translateService: TranslateService,private activatedRoute: ActivatedRoute,private rightService: RightService,private layoutService: LayoutService,private displayPipe: DisplayPipe,private store: Store<AppState>,private notificationService: NotificationService,private datePipe: DatePipe,private capitalizeFirstPipe: CapitalizeFirstPipe,private entretienService: EntretienService) { }

	/**
	 * Récupération de la classe
	 */
	getClassName: () => string = () => 'com.notilus.data.vehicule.Vehicule';

	/**
	 * Récupération de l'identifiant du véhicule à charger
	 */
	getIdObject: () => number = () => this.vehicule?.idVehicule || 0;

	/**
	 * Récupération de l'entité portant les pièces jointes
	 */
	getOwningEntity: () => LinksOwningEntity = () => this.vehicule;

	/**
	 * Initialisation du composant
	 */
	ngOnInit() {
		//Récupération de l'utilisateur courant et vérification de la présence de notifications
		combineLatest([this.store.select<{ user: User,userOrigine: User }>(state => ({ user: state.session.user,userOrigine: state.session.userOrigine }))
			,this.notificationService.isNotificationAvailableFor(this.getClassName())
		]).pipe(first()).subscribe({
			next: ([{ user,userOrigine },hasNotification]) => {
				let today: moment.Moment;

				//Définition des utilisateurs
				this.user = user;
				this.userOrigine = userOrigine;

				//Date courante
				today = moment().startOf('day');

				//Récupération de l'identifiant du vehicule à charger
				this.activatedRoute.params.pipe(first()).subscribe({
					next: params => {
						//Chargement du résumé
						this.initResume(params.idVehicule);

						//Chargement des données
						this.loadData(params.idVehicule).subscribe({
							complete: () => {
								//Initialisation de la page complexe
								this.options = {
									listeFields: [{
										libelle: this.vehicule?.typeVehicule == TypeVehicule.ENGIN ? 'vehicule.numero' : 'vehicule.immatriculation.item',
										key: 'reference'
									},this.vehicule?.typeVehicule == TypeVehicule.ENGIN ? {
										libelle: 'vehicule.marque.item',
										key: 'marque',
										type: 'DISPLAY',
										format: 'marque'
									} : {
										libelle: 'vehicule.modele.item',
										key: 'modele',
										type: 'DISPLAY',
										format: 'modele'
									},this.configurationPays?.genre && {
										libelle: 'vehicule.genre.item',
										key: (data) => data.genre ? this.displayPipe.transform(data.genre,'genre') : this.translateService.instant('common.nonCommunique')
									},this.vehicule?.pays?.idPays != this.user?.pays?.idPays && {
										libelle: 'vehicule.pays',
										key: 'pays',
										type: 'DISPLAY',
										format: 'pays'
									},{
										libelle: 'common.actif',
										key: 'actif',
										type: 'BOOLEAN'
									},this.vehicule?.extension?.dateLastAck && {
										libelle: 'vehicule.dateLastAck',
										key: 'extension.dateLastAck',
										type: 'DATE',
										format: 'datetime',
										isSecondary: true
									},[TypeVehicule.VEHICULE_FONCTION_SERVICE,TypeVehicule.ENGIN].includes(this.vehicule?.typeVehicule) && {
										libelle: 'vehicule.dateEntree.item',
										key: 'dateEntree',
										type: 'DATE',
										format: 'datetime',
										isSecondary: true
									},[TypeVehicule.VEHICULE_FONCTION_SERVICE,TypeVehicule.ENGIN].includes(this.vehicule?.typeVehicule) && {
										libelle: 'vehicule.dateSortie.item',
										key: 'dateSortie',
										type: 'DATE',
										format: 'datetime',
										isSecondary: true
									},this.configurationPays?.carburant && this.vehicule.typeVehicule != TypeVehicule.ENGIN && {
										libelle: 'vehicule.carburant.item',
										key: 'carburant',
										type: 'DISPLAY',
										format: 'carburant',
										isSecondary: true
									},this.configurationPays?.tauxCO2 && {
										libelle: 'vehicule.tauxCO2',
										key: 'tauxCO2',
										isSecondary: true
									},this.configurationPays?.puissanceFiscale && {
										libelle: 'vehicule.puissanceFiscale',
										key: 'puissanceFiscale',
										isSecondary: true
									}].filter(f => !!f),
									listeActions: [{
										libelle: 'actions.notifier',
										doAction: () => this.notificationService.showSelectionMailForEntite(this.getClassName(),this.getIdObject(),{
											getOwningEntity: () => this.vehicule,
											typeAttachment: TypeAttachment.VEHICULE
										}),
										isVisible: () => this.vehicule?.dateEntree && hasNotification
									},{
										libelle: 'vehicule.actions.accederPlanning',
										doAction: () => this.vehiculeService.goToPlanning(this.vehicule),
										isVisible: () => this.vehicule?.dateEntree && (this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') || this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'creation')) && this.vehicule.typeVehicule != TypeVehicule.ENGIN
									},{
										libelle: 'actions.renouveler',
										doAction: () => this.vehiculeService.doVehiculeRenouvellement(this.vehicule),
										isVisible: () => this.vehicule?.dateEntree && this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'creation') && !this.vehicule.demandeVehicule && this.vehicule.typeVehicule != TypeVehicule.ENGIN && this.configurationPays?.demandeVehicule
									},{
										libelle: 'actions.sortir',
										doAction: () => this.takeOutVehicule(),
										isVisible: () => this.vehicule?.dateEntree && this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation') && !this.vehicule.dateSortie && this.vehicule.typeVehicule != TypeVehicule.ENGIN
									},{
										libelle: 'actions.modifier',
										doAction: () => this.editVehicule(),
										isVisible: () => this.vehicule?.dateEntree && this.rightService.hasRight(null,'creation'),
										type: TypeAction.EDITION
									},{
										libelle: 'actions.consulter',
										doAction: () => this.editVehicule(),
										isVisible: () => this.vehicule?.dateEntree && !this.rightService.hasRight(null,'creation'),
										type: TypeAction.CONSULTATION
									},{
										libelle: 'actions.supprimer',
										doAction: () => this.deleteVehicule(),
										isVisible: () => this.vehicule?.dateEntree && this.rightService.hasRight(null,'suppression')
									}],
									isFormCustomization: true,
									listeAlertes: [{
										icon: 'warning',
										title: () => this.translateService.instant('vehicule.alerte.controleTechniqueDepasse.title',{ date: this.capitalizeFirstPipe.transform(this.datePipe.transform(this.vehicule?.extension?.dateProchainControleTechnique,'MMMM yyyy')) }),
										message: this.translateService.instant('vehicule.alerte.controleTechniqueDepasse.message'),
										isVisible: () => !this.pageContentService.isOpened() && this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation') && this.vehicule?.extension?.dateProchainControleTechnique && this.vehicule?.extension?.dateProchainControleTechnique < today && this.vehicule?.dateEntree,
										doAction: () => this.editLastEntretien('CONTROLE_REGLEMENTAIRE','TECHNIQUE'),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation')
									},{
										icon: 'warning',
										title: () => this.translateService.instant('vehicule.alerte.controleAntiPollutionDepasse.title',{ date: this.capitalizeFirstPipe.transform(this.datePipe.transform(this.vehicule?.extension?.dateProchainControleAntiPollution,'MMMM yyyy')) }),
										message: this.translateService.instant('vehicule.alerte.controleAntiPollutionDepasse.message'),
										isVisible: () => !this.pageContentService.isOpened() && this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation') && this.vehicule?.extension?.dateProchainControleAntiPollution && this.vehicule?.extension?.dateProchainControleAntiPollution < today && this.vehicule?.dateEntree,
										doAction: () => this.editLastEntretien('CONTROLE_REGLEMENTAIRE','ANTIPOLLUTION'),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation')
									},{
										icon: 'warning',
										title: () => this.translateService.instant('vehicule.alerte.entretienDepasse.title',{ date: this.capitalizeFirstPipe.transform(this.datePipe.transform(this.vehicule?.extension?.dateProchainEntretien,'MMMM yyyy')) }),
										message: this.translateService.instant('vehicule.alerte.entretienDepasse.message'),
										isVisible: () => !this.pageContentService.isOpened() && this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation') && this.vehicule?.extension?.dateProchainEntretien && this.vehicule?.extension?.dateProchainEntretien < today && this.vehicule?.dateEntree,
										doAction: () => this.editLastEntretien('ENTRETIEN'),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation')
									},{
										icon: 'warning',
										title: () => this.translateService.instant(`vehicule.alerte.demandeVehicule.title.${this.vehicule?.dateEntree && this.vehicule?.demandeVehicule?.statut || 'default'}`),
										message: this.translateService.instant('vehicule.alerte.demandeVehicule.message'),
										isVisible: () => !this.pageContentService.isOpened() && this.vehicule?.demandeVehicule,
										doAction: () => {
											//Navigation vers la commande
											this.layoutService.goToByState('vehiculeCommandeReferentiels-listeDemandesVehicule-detail',{
												routeParams: {
													idDemandeVehicule: this.vehicule.demandeVehicule.idDemandeVehicule
												},
												withGoBack: true
											})
										},
										isActionVisible: () => this.rightService.hasRight(TypeDroit.DEMANDE_VEHICULE,'consultation')
									},{
										icon: 'hourglass_bottom',
										isVisible: () => !this.pageContentService.isOpened() && !this.vehicule?.extension?.dateProchainControleTechnique && this.vehicule?.extension?.dateLastControleTechnique && moment(this.vehicule?.extension?.dateLastControleTechnique).add('2','M').subtract('1','d').isAfter(today),
										title: () => this.translateService.instant('entretien.contreVisite.alerte.technique.title',{ dateLimite: moment(this.vehicule?.extension?.dateLastControleTechnique).add('2','M').subtract('1','d').format('LL') }),
										message: () => this.translateService.instant('entretien.contreVisite.alerte.message'),
										doAction: () => this.editCurrentEntretien('CONTROLE_REGLEMENTAIRE','TECHNIQUE'),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation')
									},{
										icon: 'hourglass_bottom',
										isVisible: () => !this.pageContentService.isOpened() && !this.vehicule?.extension?.dateProchainControleAntiPollution && this.vehicule?.extension?.dateLastControleAntiPollution && moment(this.vehicule?.extension?.dateLastControleAntiPollution).add('2','M').subtract('1','d').isAfter(today),
										title: () => this.translateService.instant('entretien.contreVisite.alerte.antiPollution.title',{ dateLimite: moment(this.vehicule?.extension?.dateLastControleAntiPollution).add('2','M').subtract('1','d').format('LL') }),
										message: () => this.translateService.instant('entretien.contreVisite.alerte.message'),
										doAction: () => this.editCurrentEntretien('CONTROLE_REGLEMENTAIRE','ANTIPOLLUTION'),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation')
									}],
									listeElements: [{
										type: 'AFFECTATION',
										libelle: 'vehicule.elements.affectation',
										component: VehiculeAffectationListComponent,
										count: () => this.resume.nbAffectations,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addAffectation: this.addAffectation.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && this.vehicule?.dateEntree,
										doAction: () => this.addAffectation(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONDUCTEUR,'creation') || this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.affectation.ajouter')
									},{
										type: 'IMPUTATION',
										libelle: 'vehicule.elements.imputation',
										component: VehiculeImputationListComponent,
										count: () => this.resume.nbImputations,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addImputation: this.addImputation.bind(this),
											updateVehicule: vehicule => Object.assign(this.vehicule,vehicule)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'consultation') && this.vehicule?.dateEntree,
										doAction: () => this.addImputation(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ADMIN_VEHICULE,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.imputation.ajouter')
									},{
										type: 'CONTRAT_FINANCEMENT',
										libelle: 'vehicule.elements.contratFinancement',
										component: VehiculeContratFinancementListComponent,
										count: () => this.resume.nbContratsFinancement,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addContratFinancement: this.addContratFinancement.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'consultation') && this.vehicule?.dateEntree,
										doAction: () => this.addContratFinancement(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAT_FINANCEMENT,'creation') && this.vehicule?.dateEntree && !this.resume?.nbContratsFinancement,
										libelleAction: this.translateService.instant('vehicule.elements.contratFinancement.ajouter')
									},{
										type: 'SINISTRE',
										libelle: 'vehicule.elements.sinistre',
										component: VehiculeSinistreListComponent,
										count: () => this.resume.nbSinistres,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addSinistre: this.addSinistre.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_SINISTRE,'consultation') && this.vehicule?.dateEntree && this.vehicule?.typeVehicule != 'ENGIN',
										doAction: () => this.addSinistre(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_SINISTRE,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.sinistre.ajouter')
									},{
										type: 'INFRACTION',
										libelle: 'vehicule.elements.infraction',
										component: VehiculeInfractionListComponent,
										count: () => this.resume.nbInfractions,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addInfraction: this.addInfraction.bind(this)
										}),
										isVisible: () => (this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAVENTION,'consultation') || this.rightService.hasRight(TypeDroit.VEHICULE_DEMANDE_IDENTIFICATION,'consultation')) && this.vehicule?.dateEntree && this.vehicule?.typeVehicule != 'ENGIN',
										doAction: () => this.addInfraction(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_CONTRAVENTION,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.infraction.ajouter')
									},{
										type: 'RELEVE_COMPTEUR',
										libelle: 'vehicule.elements.releveCompteur',
										component: VehiculeReleveCompteurListComponent,
										count: () => this.resume.nbRelevesCompteur,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addReleveCompteur: this.addReleveCompteur.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_COMPTEUR,'consultation') && this.vehicule?.dateEntree,
										doAction: () => this.addReleveCompteur(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_COMPTEUR,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.releveCompteur.ajouter')
									},{
										type: 'RELEVE_CARBURANT',
										libelle: 'vehicule.elements.releveEnergie',
										component: VehiculeReleveEnergieListComponent,
										count: () => this.resume.nbRelevesCarburant,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addReleveEnergie: this.addReleveEnergie.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_RELEVE_ENERGIE,'consultation') && this.vehicule?.dateEntree && this.vehicule?.typeVehicule != 'ENGIN',
										doAction: () => this.addReleveEnergie(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_RELEVE_ENERGIE,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.releveEnergie.ajouter')
									},{
										type: 'DETAIL_FACTURE',
										libelle: 'vehicule.elements.detailFacture',
										component: VehiculeFactureDetailListComponent,
										count: () => this.resume.nbDetailsFacture,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'consultation') || this.rightService.hasRight(TypeDroit.FACTURE_DETAIL,'consultation'),
										doAction: () => {
											//Redirection vers la facture
											this.layoutService.goToByState('listeFactures-load',{
												routeParams: {
													idFacture: 0,
													objetLien: this.vehicule,
													source: 'VEHICULE'
												},
												withGoBack: true
											});
										},
										isActionVisible: () => this.rightService.hasRight(TypeDroit.FACTURE,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.detailFacture.ajouter')
									},{
										type: 'EQUIPEMENT',
										libelle: 'vehicule.elements.equipement',
										component: VehiculeEquipementListComponent,
										count: () => this.resume.nbAffectationsEquipement,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addEquipement: this.addEquipement.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.EQUIPEMENT,'consultation') && this.vehicule?.typeVehicule != 'ENGIN',
										doAction: () => this.addEquipement(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.EQUIPEMENT,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.equipement.ajouter')
									},{
										type: 'ENTRETIEN',
										libelle: 'vehicule.elements.entretien',
										component: VehiculeEntretienListComponent,
										count: () => this.resume.nbEntretiens,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addEntretien: this.addEntretien.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'consultation') && this.vehicule?.dateEntree,
										doAction: () => this.addEntretien(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ENTRETIEN,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.entretien.ajouter')
									},{
										type: 'AMENAGEMENT',
										libelle: 'vehicule.elements.amenagement',
										component: VehiculeAmenagementListComponent,
										count: () => this.resume.nbAmenagements,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addAmenagement: this.addAmenagement.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_AMENAGEMENT,'consultation') && this.vehicule?.dateEntree && this.vehicule?.typeVehicule != 'ENGIN',
										doAction: () => this.addAmenagement(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.VEHICULE_AMENAGEMENT,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.amenagement.ajouter')
									},{
										type: 'ETAT_LIEUX',
										libelle: 'vehicule.elements.etatLieux',
										component: VehiculeEtatLieuxListComponent,
										count: () => this.resume.nbEtatsLieux,
										retrieveComponentData: () => ({
											vehicule: this.vehicule,
											resume: this.resume,
											addEtatLieux: this.addEtatLieux.bind(this)
										}),
										isVisible: () => this.rightService.hasRight(TypeDroit.ETAT_LIEUX,'consultation') && this.vehicule?.dateEntree && this.vehicule?.typeVehicule != 'ENGIN',
										doAction: () => this.addEtatLieux(),
										isActionVisible: () => this.rightService.hasRight(TypeDroit.ETAT_LIEUX,'creation'),
										libelleAction: this.translateService.instant('vehicule.elements.etatLieux.ajouter')
									}]
								};
							}
						});
					}
				});
			}
		});
	}

	/**
	 * Chargement du résumé
	 */
	protected initResume(idVehicule: number) {
		//Chargement du résumé
		this.vehiculeService.loadResume(idVehicule).subscribe({
			next: (result: Result) => {
				//Vérification de la présence d'une erreur
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du résumé
					Object.assign(this.resume,{
						nbSinistres: result.data?.nbSinistres || 0,
						nbContraventions: result.data?.nbContraventions || 0,
						nbImputations: result.data?.nbImputations || 0,
						nbAffectations: result.data?.nbAffectations || 0,
						nbRelevesCompteur: result.data?.nbRelevesCompteur || 0,
						nbRelevesCarburant: result.data?.nbRelevesCarburant || 0,
						nbContratsFinancement: result.data?.nbContratsFinancement || 0,
						nbDetailsFacture: result.data?.nbDetailsFacture || 0,
						nbAffectationsEquipement: result.data?.nbAffectationsEquipement || 0,
						nbEntretiens: result.data?.nbEntretiens || 0,
						nbAmenagements: result.data?.nbAmenagements || 0,
						nbEtatsLieux: result.data?.nbEtatsLieux || 0,
						nbInfractions: result.data?.nbInfractions || 0
					});
				}
			}
		});
	}

	/**
	 * Edition du dernier entretien
	 */
	public editLastEntretien(typeEntretien: 'CONTROLE_REGLEMENTAIRE' | 'ENTRETIEN',sousType?: 'TECHNIQUE' | 'ANTIPOLLUTION') {
		let date: any;
		//Vérification du type d'entretien
		if (typeEntretien == 'CONTROLE_REGLEMENTAIRE' && sousType)
			//Récupération de la date du prochain contrôle technique ou contrôle anti-pollution
			date = sousType == 'TECHNIQUE' ? this.vehicule?.extension?.dateProchainControleTechnique : this.vehicule?.extension?.dateProchainControleAntiPollution;
		else if (typeEntretien == 'ENTRETIEN')
			//Récupération de la date du prochain entretien
			date = this.vehicule?.extension?.dateProchainEntretien;

		//Chargement de l'entretien
		date && this.entretienService.findEntretienByVehiculeTypeAndDatePrevisionnelle(this.vehicule,typeEntretien,date,sousType).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR && result?.data?.entretien)
					//Edition de l'entretien
					this.addEntretien(result.data.entretien);
			}
		});
	}

	/**
	 * Edition de l'entretien en attente de contre-visite
	 */
	public editCurrentEntretien(typeEntretien: 'CONTROLE_REGLEMENTAIRE' | 'ENTRETIEN',sousType?: 'TECHNIQUE' | 'ANTIPOLLUTION') {
		let date: any;

		//Vérification du type d'entretien
		if (typeEntretien == 'CONTROLE_REGLEMENTAIRE' && sousType)
			//Récupération de la date du prochain contrôle technique ou contrôle anti-pollution
			date = sousType == 'TECHNIQUE' ? this.vehicule?.extension?.dateLastControleTechnique : this.vehicule?.extension?.dateLastControleAntiPollution;

		//Chargement de l'entretien
		date && this.entretienService.findEntretienByVehiculeTypeAndDateRealisation(this.vehicule,typeEntretien,date,sousType).subscribe({
			next: (result: Result) => {
				//Vérification du code d'erreur
				if (result.codeErreur == TypeCodeErreur.NO_ERROR && result?.data?.entretien)
					//Edition de l'entretien
					this.addEntretien(result.data.entretien);
			}
		});
	}

	/**
	 * Chargement des données
	 */
	private loadData(idVehicule: number,immatriculation: any = history.state.immatriculation,modeleConstructeur: any = history.state.modele): Subject<any> {
		let subject: Subject<any> = new Subject<any>();

		//Chargement du véhicule
		this.vehiculeService.loadVehicule(idVehicule,immatriculation?.pays?.code2).subscribe({
			next: (result: Result) => {
				//Vérification du chargement
				if (result?.codeErreur == TypeCodeErreur.NO_ERROR) {
					//Définition du véhicule
					this.vehicule = result.data?.vehicule || {
						actif: true,
						typeVehicule: TypeVehicule.VEHICULE_FONCTION_SERVICE,
						critAir: 'AUCUN',
						uniteUsage: 'DISTANCE',
						pays: immatriculation?.pays,
						listeFinancements: []
					};

					//Définition de la configuration du pays
					this.configurationPays = result.data?.configurationPays;

					//Vérification de la présence d'une immatriculation
					if (this.vehicule?.immatriculation)
						//Définition de la liste des modèles
						this.listeImmatriculationModeles = result.data?.listeImmatriculationModeles;

					//Vérification de la demande de véhicule
					if (result?.data?.demandeVehicule) {
						//Vérification de la date d'entrée du véhicule (véhicule en commande)
						if (this.vehicule?.dateEntree == null)
							//Définition de la demande de véhicule pour le véhicule en commande
							this.vehicule.demandeVehicule = result.data?.demandeVehicule;
						else
							//Définition de la demande de véhicule
							this.demandeVehicule = result.data?.demandeVehicule;
					}

					//Initialisation du compteur de pièces jointes
					this.attachmentService.initAttachments(this);

					//Vérification de la présence d'un certificat d'immatriculation
					if (!this.vehicule?.immatriculation && immatriculation) {
						//Mise à jour du certificat d'immatriculation
						this.vehicule.immatriculation = immatriculation;

						//Récupération de la référence du véhicule
						this.vehicule.reference = this.vehicule.immatriculation.reference;

						//Mise à jour du modèle
						this.vehicule.modeleConstructeur = modeleConstructeur;

						//Vérification du modèle constructeur
						if (modeleConstructeur)
							//Définition de la capacité du réservoir
							this.vehicule.capaciteReservoir = modeleConstructeur.capaciteReservoir;

						//Vérification de l'identifiant
						if (!this.vehicule.idVehicule || this.vehicule.dateEntree == null)
							//Passage en mode 'Edition'
							this.editVehicule();
					} else {
						//Vérification du passage d'un véhicule en paramètre
						if (history.state.vehicule)
							//Complétion du véhicule
							Object.assign(this.vehicule,history.state.vehicule);

						//Vérification de l'absence d'identifiant ou d'immatriculation
						if (!this.vehicule.idVehicule || !this.vehicule.immatriculation && this.vehicule.typeVehicule == TypeVehicule.VEHICULE_FONCTION_SERVICE)
							//Passage en mode 'Edition'
							this.editVehicule();
					}

					//Vérification de la nécessité de sortir le véhicule
					if (history.state.isSortieNeeded)
						//Sortie du véhicule
						this.takeOutVehicule();
				}

				//Finalisation du chargement des données
				subject.complete();
			},
			complete: () => subject.complete()
		});

		return subject;
	}

	/**
	 * Edition du véhicule
	 */
	private editVehicule() {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeEditComponent,{
			data: {
				vehicule: cloneDeep(this.vehicule),
				user: this.user,
				userOrigine: this.userOrigine,
				configurationPays: this.configurationPays,
				listeImmatriculationModeles: this.listeImmatriculationModeles,
				deleteVehicule: this.deleteVehicule.bind(this)
			}
		}).subscribe({
			next: (vehicule: any) => {
				//Vérification des données
				if (vehicule) {
					//Mise à jour du véhicule
					Object.assign(this.vehicule,vehicule);

					//Mise à jour de l'identifiant contenu dans l'url
					this.layoutService.replaceUrlWith(this.vehicule);
				}
			}
		});
	}

	/**
	 * Suppression du véhicule
	 */
	protected deleteVehicule() {
		//Affichage d'un message de confirmation
		this.confirmService.showConfirm(this.translateService.instant('actions.suppression.confirmation')).pipe(
			filter(isConfirmed => !!isConfirmed),
			switchMap(() => this.vehiculeService.deleteVehicule(this.vehicule))
		).subscribe((result: Result) => {
			//Vérification du code d'erreur
			if (result?.codeErreur === TypeCodeErreur.NO_ERROR) {
				//Message d'information
				this.toastrService.success(this.translateService.instant('actions.suppression.success'));

				//Retour à la page précédente
				this.layoutService.goBack();
			} else
				//Message d'erreur
				this.toastrService.error(this.translateService.instant('actions.suppression.error'));
		});
	}

	/**
	 * Ajout/modification d'une affectation
	 */
	protected addAffectation(affectation: any = { vehicule: Object.assign({},this.vehicule),synchroImputationUser: true },deleteAffectation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeAffectationEditComponent,{
			data: {
				affectation: cloneDeep(affectation),
				user: this.user,
				vehicule: this.vehicule,
				deleteAffectation
			}
		},'sub').subscribe({
			next: (data: { affectation: any,vehicule?: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('AFFECTATION',affectation.idAffectation != data.affectation?.idAffectation);

					//Vérification de la présence d'une création ou d'une suppression
					if (!affectation.idAffectation && data.affectation?.idAffectation)
						//Incrémentation du compteur
						this.resume.nbAffectations++;
					else if (affectation.idAffectation && !data.affectation?.idAffectation)
						//Décrémentation du compteur
						this.resume.nbAffectations--;

					//Mise à jour de l'affectation
					Object.assign(affectation,data.affectation);

					//Mise à jour du véhicule
					Object.assign(this.vehicule,data.vehicule);

					//Mise à jour du résumé
					data.affectation && this.initResume(this.vehicule.idVehicule);
				}
			}
		});
	}

	/**
	 * Modification du contrat de financement
	 */
	protected addContratFinancement(contratFinancement?: any,deleteContratFinancement?: Function) {
		//Vérification du contrat de financement
		if (!contratFinancement) {
			//Définition du contrat de financement
			contratFinancement = {
				typeContrat: 'FINANCEMENT',
				typeDuree: 'MOIS',
				typeDureeAmortissement: 'MOIS',
				listeLoisRoulage: [],
				unite: this.user.unite,
				devise: this.user.devise,
				vehicule: this.vehicule
			}
		}

		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeContratFinancementEditComponent,{
			data: {
				contratFinancement: cloneDeep(contratFinancement),
				deleteContratFinancement
			}
		},'sub').subscribe({
			next: (data: { contratFinancement: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet
					this.complexPage.setSelectedElementByType('CONTRAT_FINANCEMENT',true);

					//Vérification de la présence d'une création ou d'une suppression
					if (!contratFinancement.idContrat && data.contratFinancement?.idContrat)
						//Incrémentation du compteur
						this.resume.nbContratsFinancement++;
					else if (contratFinancement.idContrat && !data.contratFinancement?.idContrat)
						//Décrémentation du compteur
						this.resume.nbContratsFinancement--;

					//Mise à jour du contrat de financement
					Object.assign(contratFinancement,data.contratFinancement);
				}
			}
		});
	}

	/**
	 * Ajout/modification d'une imputation
	 */
	protected addImputation(imputation: any = { vehicule: Object.assign({},this.vehicule) },deleteImputation?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeImputationEditComponent,{
			data: {
				imputation: cloneDeep(imputation),
				deleteImputation,
				vehicule: this.vehicule
			}
		},'sub').subscribe({
			next: (data: { imputation: any,vehicule?: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Vérification de la présence d'une imputation
					if (data.imputation) {
						//Affichage de l'onglet (si nécessaire)
						this.complexPage.setSelectedElementByType('IMPUTATION',imputation.idImputation != data.imputation?.idImputation);

						//Vérification de la présence d'une création ou d'une suppression
						if (!imputation.idImputation && data.imputation?.idImputation)
							//Incrémentation du compteur
							this.resume.nbImputations++;
						else if (imputation.idImputation && !data.imputation?.idImputation)
							//Décrémentation du compteur
							this.resume.nbImputations--;

						//Mise à jour de l'imputation
						Object.assign(imputation,data.imputation);
					}

					//Mise à jour du véhicule
					Object.assign(this.vehicule,data.vehicule);
				}
			}
		});
	}

	/**
	 * Ajout/modification d'un sinistre
	 */
	protected addSinistre(sinistre: any = { vehicule: Object.assign({},this.vehicule),statut: 'EN_COURS' },deleteSinistre?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeSinistreEditComponent,{
			data: {
				sinistre: cloneDeep(sinistre),
				deleteSinistre
			}
		},'sub').subscribe({
			next: (data: { sinistre: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('SINISTRE',sinistre.idSinistre != data.sinistre?.idSinistre);

					//Vérification de la présence d'une création ou d'une suppression
					if (!sinistre.idSinistre && data.sinistre?.idSinistre)
						//Incrémentation du compteur
						this.resume.nbSinistres++;
					else if (sinistre.idSinistre && !data.sinistre?.idSinistre)
						//Décrémentation du compteur
						this.resume.nbSinistres--;

					//Mise à jour du sinistre
					Object.assign(sinistre,data.sinistre);
				}
			}
		});
	}

	/**
	 * Sortie du véhicule
	 */
	protected takeOutVehicule() {
		//Sortie du véhicule
		this.vehiculeService.takeOutVehicule(this.vehicule,this.configurationPays).pipe(filter(savedVehicule => !!savedVehicule)).subscribe({
			next: (savedVehicule: any) => {
				//Définiton du véhicule
				Object.assign(this.vehicule,savedVehicule);
			}
		});
	}

	/**
	 * Redirection vers la contravention
	 */
	private addInfraction(vehicule: any = this.vehicule) {
		//Redirection vers la contravention
		this.layoutService.goToByState('infractionReferentiel-listeVehiculeContraventions-contravention',{
			routeParams: {
				idContravention: 0
			},
			routeData: {
				vehicule: vehicule
			},
			reloadOnSameUrl: true,
			withGoBack: true
		});
	}

	/**
	 * Modification du relevé de compteur
	 */
	protected addReleveCompteur(releveCompteur: any = { vehicule: Object.assign({},this.vehicule),valeurReference: true,typeSourceReleve: 'COLLABORATEUR' },deleteReleveCompteur?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeReleveCompteurEditComponent,{
			data: {
				releveCompteur: cloneDeep(releveCompteur),
				deleteReleveCompteur
			}
		},'sub').subscribe({
			next: (data: { releveCompteur: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('RELEVE_COMPTEUR',releveCompteur.idCompteur != data.releveCompteur?.idCompteur);

					//Vérification de la présence d'une création ou d'une suppression
					if (!releveCompteur.idCompteur && data.releveCompteur?.idCompteur)
						//Incrémentation du compteur
						this.resume.nbRelevesCompteur++;
					else if (releveCompteur.idCompteur && !data.releveCompteur?.idCompteur)
						//Décrémentation du compteur
						this.resume.nbRelevesCompteur--;

					//Mise à jour du relevé de compteur
					Object.assign(releveCompteur,data.releveCompteur);
				}
			}
		});
	}

	/**
	 * Modification du relevé d'énergie
	 */
	protected addReleveEnergie(releveEnergie: any = { vehicule: Object.assign({},this.vehicule),unite: this.vehicule.carburant?.uniteDefaut || this.user.uniteVolume,typeSourceReleve: 'COLLABORATEUR' },deleteReleveEnergie?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeReleveEnergieEditComponent,{
			data: {
				releveEnergie: cloneDeep(releveEnergie),
				deleteReleveEnergie
			}
		},'sub').subscribe({
			next: (data: { releveEnergie: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('RELEVE_CARBURANT',releveEnergie.idReleve != data.releveEnergie?.idReleve);

					//Vérification de la présence d'une création ou d'une suppression
					if (!releveEnergie.idReleve && data.releveEnergie?.idReleve)
						//Incrémentation du compteur
						this.resume.nbRelevesCarburant++;
					else if (releveEnergie.idReleve && !data.releveEnergie?.idReleve)
						//Décrémentation du compteur
						this.resume.nbRelevesCarburant--;

					//Mise à jour du relevé d'énergie
					Object.assign(releveEnergie,data.releveEnergie);
				}
			}
		});
	}

	/**
	 * Modification d'une affectation à un équipement
	 */
	protected addEquipement(affectationEquipement: any = { vehicule: Object.assign({},this.vehicule),type: 'VEHICULE',equipement: {} },deleteAffectationEquipement?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeEquipementEditComponent,{
			data: {
				affectationEquipement: cloneDeep(affectationEquipement),
				deleteAffectationEquipement
			}
		},'sub').subscribe({
			next: (data: { affectationEquipement: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('EQUIPEMENT',affectationEquipement.idAffectation != data.affectationEquipement?.idAffectation);

					//Vérification de la présence d'une création ou d'une suppression
					if (!affectationEquipement.idAffectation && data.affectationEquipement?.idAffectation)
						//Incrémentation du compteur
						this.resume.nbAffectationsEquipement++;
					else if (affectationEquipement.idAffectation && !data.affectationEquipement?.idAffectation)
						//Décrémentation du compteur
						this.resume.nbAffectationsEquipement--;

					//Mise à jour de l'affectation à l'équipement
					Object.assign(affectationEquipement,data.affectationEquipement);
				}
			}
		});
	}

	/**
	 * Modification de l'aménagement
	 */
	protected addAmenagement(amenagement: any = { vehicule: Object.assign({},this.vehicule) },deleteAmenagement?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeAmenagementEditComponent,{
			data: {
				amenagement: cloneDeep(amenagement),
				deleteAmenagement
			}
		},'sub').subscribe({
			next: (data: { amenagement: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('AMENAGEMENT',amenagement.idAmenagement != data.amenagement?.idAmenagement);

					//Vérification de la présence d'une création ou d'une suppression
					if (!amenagement.idAmenagement && data.amenagement?.idAmenagement)
						//Incrémentation du compteur
						this.resume.nbAmenagements++;
					else if (amenagement.idAmenagement && !data.amenagement?.idAmenagement)
						//Décrémentation du compteur
						this.resume.nbAmenagements--;

					//Mise à jour de l'aménagement
					Object.assign(amenagement,data.amenagement);
				}
			}
		});
	}

	/**
	 * Modification de l'état des lieux
	 */
	protected addEtatLieux(etatLieux: any = { vehicule: Object.assign({},this.vehicule) },deleteEtatLieux?: Function) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeEtatLieuxEditComponent,{
			data: {
				etatLieux: cloneDeep(etatLieux),
				deleteEtatLieux
			}
		},'sub').subscribe({
			next: (data: { etatLieux: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (si nécessaire)
					this.complexPage.setSelectedElementByType('ETAT_LIEUX',etatLieux.idEtatLieux != data.etatLieux?.idEtatLieux);

					//Vérification de la présence d'une création ou d'une suppression
					if (!etatLieux.idEtatLieux && data.etatLieux?.idEtatLieux)
						//Incrémentation du compteur
						this.resume.nbEtatsLieux++;
					else if (etatLieux.idEtatLieux && !data.etatLieux?.idEtatLieux)
						//Décrémentation du compteur
						this.resume.nbEtatsLieux--;

					//Mise à jour de l'état des lieux
					Object.assign(etatLieux,data.etatLieux);
				}
			}
		});
	}

	/**
	 * Modification d'un entretien
	 */
	protected addEntretien(entretien: any = { vehicule: Object.assign({},this.vehicule) },deleteEntretien?: Function,lastCompteur?: any,nbInterventions?: number) {
		//Ouverture du composant d'édition
		this.pageContentService.open(VehiculeEntretienEditComponent,{
			data: {
				entretien: cloneDeep(entretien),
				deleteEntretien,
				lastCompteur,
				nbInterventions,
				updateVehicule: vehicule => Object.assign(this.vehicule,vehicule)
			}
		},'sub').subscribe({
			next: (data: { entretien: any }) => {
				//Vérification de la présence de données
				if (data) {
					//Affichage de l'onglet (avec rechargement forcé)
					this.complexPage.setSelectedElementByType('ENTRETIEN',true);

					//Vérification de la présence d'une création ou d'une suppression
					if (!entretien.idEntretien && data.entretien?.idEntretien)
						//Incrémentation du compteur
						this.resume.nbEntretiens++;
					else if (entretien.idEntretien && !data.entretien?.idEntretien)
						//Décrémentation du compteur
						this.resume.nbEntretiens--;

					//Mise à jour de l'entretien
					Object.assign(entretien,data.entretien);

					//Mise à jour du résumé si nécessaire
					data.entretien && this.initResume(this.vehicule.idVehicule);
				}
			}
		});
	}
}